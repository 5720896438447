import React, { useState, createContext, useContext, useEffect, useMemo, useCallback } from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import './style.sass'

const MessageContext = createContext()

const Message = ({ children, type }) => {
	const isError = type === 'error'
	const isSuccess = type === 'success'

	const cs = cx('Message', {
		'Message--error': isError,
		'Message--success': isSuccess,
	})

	return (
		<div className='AnimatedMessageContainer'>
			<div className={cs}>{children}</div>
		</div>
	)
}

export const MessageProvider = ({ children }) => {
	const [message, setMessage] = useState({ value: '' })
	const [type, setType] = useState('success')
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (message.value !== '') {
			setVisible(true)
			setTimeout(() => {
				setVisible(false)
			}, 5000)
		}
	}, [message])

	const setMessageInfo = (message, type) => {
		setMessage(() => ({ value: message }))
		setType(type)
	}

	const setSuccessMessage = useCallback(message => setMessageInfo(message, 'success'), [])

	const setErrorMessage = useCallback(message => setMessageInfo(message, 'error'), [])

	const value = useMemo(() => ({ setSuccessMessage, setErrorMessage }), [setErrorMessage, setSuccessMessage])

	return (
		<MessageContext.Provider value={value}>
			{children}
			{ReactDOM.createPortal(
				<>{visible && <Message type={type}>{message.value}</Message>}</>,
				document.getElementById('message-root'),
			)}
		</MessageContext.Provider>
	)
}

export const withMessage = Component => {
	return props => {
		const message = useContext(MessageContext)

		return <Component {...props} setErrorMessage={message.setErrorMessage} setSuccessMessage={message.setSuccessMessage} />
	}
}
