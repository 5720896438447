import React, { useMemo } from 'react'
import cs from 'classnames'

import './style.sass'

export default function Loading({ variant, className, width = '', height = '' }: any) {
	const cx = cs('LoadingContainer', className, {
		'LoadingContainer--rect': variant === 'rect',
		'LoadingContainer--circle': variant === 'circle',
		'LoadingContainer--text': variant === 'text',
	})
	const style = useMemo(() => ({ width, height }), [width, height])
	return <div style={style} className={cx}></div>
}
