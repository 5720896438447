import DateFnsAdapter from '@date-io/date-fns'
import format from 'date-fns/format'

export const dateFns = new DateFnsAdapter()

export function formatDate(date) {
	const initialDateFnsDate = dateFns.date(date)
	return dateFns.format(initialDateFnsDate, 'fullDateTime24h')
}

export function resetSeconds(date) {
	date.setSeconds(0, 0)
}

function addZeroBeforeNumberLessThan10(num: number): string | number {
	return num < 10 ? `0${num}` : num
}

function getMonth(dateObject: Date): string {
	return addZeroBeforeNumberLessThan10(dateObject.getUTCMonth() + 1)
}

function getDay(dateObject: Date): string {
	return addZeroBeforeNumberLessThan10(dateObject.getUTCDate())
}

function getHourMinutesAndSeconds(dateObject: Date): string {
	return `${addZeroBeforeNumberLessThan10(dateObject.getUTCHours())}:${addZeroBeforeNumberLessThan10(
		dateObject.getUTCMinutes(),
	)}:${addZeroBeforeNumberLessThan10(dateObject.getUTCSeconds())}`
}

export const formatDateAsAPIParam = (date: Date | string | null): string | null => {
	if (date) {
		const dateObject = new Date(date)
		return `${dateObject.getUTCFullYear()}/${getMonth(dateObject)}/${getDay(dateObject)} ${getHourMinutesAndSeconds(
			dateObject,
		)}`
	}
	return null
}

export const formatDateForUserDisplay = (date: string, isUTCTime = true): string => {
	if (isUTCTime) date += ' UTC'
	const dateObject = new Date(date)
	return format(dateObject, 'yyyy/MM/dd HH:mm:ss')
}

export const formatDateObjectForUserDisplay = (date: string): string => {
	let utcDate = (date += ' UTC')
	const dateObject = new Date(utcDate)
	return formatDate(dateObject)
}

export const formatDateToDayMonthYear = (date: Date): string => {
	return format(date, 'dd-MMM-yyyy')
}
