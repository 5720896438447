// @flow
import React, { type Node } from 'react'
import cx from 'classnames'
import './style.sass'
import Icon, { type IconNamesEnum } from '../Icon'

export type ButtonLinkProps = {
	small?: boolean,
	className?: string,
	props?: {
		[string]: mixed,
	},
	children: Node,
	iconName: IconNamesEnum,
	stroke?: string,
}

export const ButtonLink = ({
	small,
	className,
	iconName,
	stroke = '#3B57F7',
	...props
}: ButtonLinkProps): React$Element<'button'> => {
	const cs: string = cx('ButtonLinkComponent', className, { 'ButtonLinkComponent--small': small })

	return (
		<button {...props} className={cs}>
			<Icon className='ButtonLinkComponent__arrow' stroke={stroke} name={iconName} width={16} height={16} />
			<span className='ButtonLinkComponent__content'>{props.children}</span>
		</button>
	)
}

export default ButtonLink
