// @flow

import Request from './request'

import type {
	LoginData,
	BusinessRequestShape,
	RequestPasswordReset,
	ChangeCurrentPassword,
	ChangePasswordFromSettings,
	PhoneConfirmationRequestShape,
	IndividualDataRegistrationShape,
	PhoneActivationRequestShape,
} from '../types/api/requests/auth'

import type { AxiosResponseType } from '../types/api/responses/common'
import type { RegisterUserResponseType, RegisterBusinessResponseType } from '../types/api/responses/auth'
import type { Country } from '../types/api/responses/countries'

export const loginUser = (data: LoginData): Promise<AxiosResponseType<any>> => {
	return Request.post({ url: '/sessions', data })
}

export const registerNewUser = (data: IndividualDataRegistrationShape): Promise<AxiosResponseType<RegisterUserResponseType>> => {
	return Request.post({ url: '/users', data })
}

export const resetPasswordRequest = (data: RequestPasswordReset): Promise<any> => {
	return Request.post({ url: '/password_resets', data })
}

export const changeCurrentPassword = (data: ChangeCurrentPassword): Promise<any> => {
	return Request.post({ url: '/reset-password', data })
}

export const sendPhoneVerificationCodeForLoggedOutUser = (data: PhoneConfirmationRequestShape): Promise<any> => {
	return Request.post({ url: '/phone_confirmations', data })
}

export const sendPhoneVerificationCodeForAuthenticatedUser = (data: PhoneConfirmationRequestShape): Promise<any> => {
	return Request.post({ url: `/users/${data.user_id}/updated_phone_confirmations`, data })
}

export const logoutUser = (): Promise<any> => {
	return Request.delete({
		url: '/sessions',
	})
}

export const changePassword = (data: ChangePasswordFromSettings): Promise<any> => {
	return Request.put({
		url: '/change_password',
		data,
	})
}

export const activatePhoneForLoggedOutUser = (data: PhoneActivationRequestShape): Promise<any> => {
	return Request.post({
		url: '/phone_activations',
		data,
	})
}

export const activatePhoneForAuthenticatedUser = (data: PhoneActivationRequestShape): Promise<any> => {
	return Request.post({
		url: `/users/${data.user_id}/updated_phone_activations`,
		data,
	})
}

export const createBusinessAccount = ({
	data,
}: {
	data: BusinessRequestShape,
}): Promise<AxiosResponseType<RegisterBusinessResponseType>> => {
	return Request.post({
		url: '/organizations',
		data,
	})
}

export const requestEmailVerificationLinkForLoggedOutUser = (
	user_id: number,
): Promise<AxiosResponseType<{ success: boolean }>> => {
	return Request.post({
		url: `/email_confirmations`,
		data: {
			user_id,
		},
	})
}

export const requestEmailVerificationLinkForAuthenticatedUser = (
	user_id: number,
): Promise<AxiosResponseType<{ success: boolean }>> => {
	return Request.post({
		url: `/users/${user_id}/updated_email_confirmations`,
		data: {
			user_id,
		},
	})
}

export const sendEmailActivationKeyForLoggedOutUser = (data: {
	user_id: number,
	code: string,
}): Promise<AxiosResponseType<{ success: boolean }>> => {
	return Request.post({
		url: `/email_activations`,
		data,
	})
}

export const sendEmailActivationKeyForAuthenticatedUser = (data: {
	user_id: number,
	code: string,
}): Promise<AxiosResponseType<{ success: boolean }>> => {
	return Request.post({
		url: `/users/${data.user_id}/updated_email_activations`,
		data,
	})
}

export const getCountryCodeByIp = (): Promise<AxiosResponseType<Country>> => {
	return Request.get({
		url: `/countries/country-by-ip`,
	})
}

export const blockContactInfoUpdate = (data: { userId: number, code: string }): Promise<AxiosResponseType<any>> => {
	return Request.post({
		url: `/block-contact-update`,
		data,
	})
}

export const verifyOTP = (data: { session_id: number, code: string }): Promise<any> => {
	return Request.put({
		url: `/sessions`,
		data,
	}) 
}
