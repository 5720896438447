import useDebouncedSWR from 'hooks/data/useDebouncedSWRHook'
import { listSurveyQuestions } from 'api/surveys'

import type { PaginationParams } from 'types/api/requests/common'
import type { AxiosResponseType, CommonError } from 'types/api/responses/common'
import type { questionListResponse } from 'types/api/responses/surveys'
type hookParams = {
	params: PaginationParams
	surveyID: number | null
}

type hookReturn = {
	data: AxiosResponseType<questionListResponse>
	error: CommonError
	mutate: (
		data?: AxiosResponseType<questionListResponse> | Promise<AxiosResponseType<questionListResponse>>,
		shouldRevalidate?: boolean,
	) => Promise<void | AxiosResponseType<questionListResponse>>
	isLoading: boolean
}

export default function useQuestions({ params, surveyID }: hookParams): hookReturn {
	const {
		data,
		error,
		mutate,
		isValidating: isLoading,
	} = useDebouncedSWR<AxiosResponseType<questionListResponse>, CommonError>(
		surveyID
			? [`/surveys/${surveyID}/survey_questions`, params.term, params.from, params.to, params.per, params.page, params.type]
			: null,
		() => listSurveyQuestions(surveyID, params),
	)

	return { data, error, mutate, isLoading }
}
