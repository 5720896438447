import { INACTIVE_SESSION_ERROR_CODE } from 'const'
import { removeAuthCookies } from 'logic/auth'
export const refreshTokenMiddleware = axios =>
	axios.interceptors.response.use(
		function(response) {
			if (response && response.headers && response.headers['x-smsleopard-token']) {
				localStorage.setItem('x-smsleopard-token', response.headers['x-smsleopard-token'])
			}
			return response
		},
		function(error) {
			return Promise.reject(error)
		},
	)

export const checkForExpiredToken = axios =>
	axios.interceptors.response.use(
		function(response) {
			return response
		},
		function(e) {
			if (e.response) {
				const { status } = e.response
				if (status === 401) redirectToLogin()
			}
			return Promise.reject(e)
		},
	)

export const confirmSessionStatus = axios => {
	axios.interceptors.response.use(
		response => response,
		error => {
			if (error.response.data?.error_code === INACTIVE_SESSION_ERROR_CODE) {
				window.location.href.replace('/otp-verification')
			}
			return Promise.reject(error)
		},
	)
}

function redirectToLogin() {
	const keysToRemove = ['x-smsleopard-token', 'x-smsleopard-account-id', 'x-smsleopard-organization-id', 'accounts']
	keysToRemove.map(item => localStorage.removeItem(item))
	removeAuthCookies()
	if (window.location && window.location.pathname !== '/login') {
		const origin = window.location.origin
		let newURL = window.location.href
		if (!window.location.search) newURL = newURL.replace(origin, origin + `/login?prev=`)
		window.location.href = newURL
	}
}
