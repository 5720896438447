import React from 'react'

import { Loading } from 'ui-common'

import './style.sass'

export default function LoadingComponent() {
	return (
		<div className='MessageDetails'>
			<div className='MessageDetailsContainer'>
				<Loading className='MessageDetailsContainer__FirstLoadingIndicator' height='50vh' />
				<Loading height='60vh' />
			</div>
		</div>
	)
}
