// @flow

import React, { type Node } from 'react'

import { Paper } from 'ui-common'
import './style.sass'

type Props = {
	title: string,
	optionalHeaderChildren?: Node,
	subtitle?: string,
	children: Node,
}
export default function CentralPaper(props: Props): React$Element<'div'> {
	return (
		<div>
			<Paper className='CentralPaperContainer'>
				<div className='CentralPaperContainer__content'>
					<h1 className='CentralPaperContainer__content__header'>{props.title}</h1>
					<p>{props.subtitle}</p>
					{props.optionalHeaderChildren && <div>{props.optionalHeaderChildren}</div>}
				</div>
				<hr className='CentralPaperContainer__divider' />
				<div className='CentralPaperContainer__content'>{props.children}</div>
			</Paper>
		</div>
	)
}
