import './loading.sass'
import React from 'react'
import { Loading } from 'ui-common'

const BulkMoneyFirstStepLoading = () => {
	return (
		<div className='BulkMoneyFirstStepLoading'>
			<Loading variant='rect' width='100%' height='80%' className='BulkMoneyFirstStepLoading__IntroRect' />
			<Loading variant='rect' width='100%' height='80%' className='BulkMoneyFirstStepLoading__DescriptionContainer' />
		</div>
	)
}

export default BulkMoneyFirstStepLoading
