// @flow

import { setAnalyticsUserProperties } from 'utils/analytics'
import parseToken from './parseToken'

import type { credentials } from 'types/auth'

const authMap = {
	token: 'x-smsleopard-token',
	account_id: 'x-smsleopard-account-id',
	organization_id: 'x-smsleopard-organization-id',
}

function createCredentialsObject({
	accountId,
	organizationId,
	token,
}: {
	accountId: string,
	organizationId: string,
	token: string,
}): credentials | null {
	const parsedToken = parseToken(token)
	if (!parsedToken) return null
	const userId = {
		_brand: 'user_id',
		value: parsedToken.user_id,
	}

	const accountIdProp = {
		_brand: 'account_id',
		value: Number(accountId),
	}

	const organizationIdProp = {
		_brand: 'organization_id',
		value: Number(organizationId),
	}

	const tokenProp = {
		_brand: 'token',
		value: token,
	}
	return {
		userId,
		accountId: accountIdProp,
		organizationId: organizationIdProp,
		token: tokenProp,
	}
}

export function readCookies(): { [string]: string } {
	const allCookies = document.cookie
	const cookiesArray = allCookies.split(';')
	return cookiesArray.reduce((acc, curr) => {
		curr = curr.trim()
		const currentCookie = curr.split('=')
		const name = currentCookie[0]
		const value = currentCookie[1]
		acc[name] = value
		return acc
	}, {})
}

type SaveCookiesType = {
	authToken: string,
	accountId: string,
	organizationId: string,
}

type Authenticated = {
	state: 'authenticated',
	credentials: credentials,
}

type Unauthenticated = {
	state: 'unauthenticated',
}

type AuthenticationState = Authenticated | Unauthenticated

function saveCookiesToLocalStorage({ authToken, accountId, organizationId }: SaveCookiesType) {
	localStorage.setItem(authMap.token, authToken)
	localStorage.setItem(authMap.account_id, accountId)
	localStorage.setItem(authMap.organization_id, organizationId)
}

function userAuthStateInMainDomain(): AuthenticationState {
	const cookiesMap = readCookies()
	const authToken = cookiesMap['auth_token']
	const accountId = cookiesMap['account_id']
	const organizationId = cookiesMap['organization_id']

	if (authToken && accountId && organizationId) {
		setAnalyticsUserProperties({ token: authToken })
		saveCookiesToLocalStorage({ authToken, accountId, organizationId })
		const credentials = createCredentialsObject({ accountId, organizationId, token: authToken })
		return credentials ? { state: 'authenticated', credentials } : { state: 'unauthenticated' }
	}
	return { state: 'unauthenticated' }
}

export function removeAuthCookies() {
	const relevantCookies = ['auth_token', 'account_id', 'organization_id']
	relevantCookies.forEach(name => {
		const domain = process.env.NODE_ENV === 'production' ? '.smsleopard.com' : 'localhost'
		document.cookie = `${name}=;domain=${domain};path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
	})
}

export default function userAuthenticationState(): AuthenticationState {
	const token = localStorage.getItem(authMap.token)
	const accountId = localStorage.getItem(authMap.account_id)
	const organizationId = localStorage.getItem(authMap.organization_id)

	if (token && accountId && organizationId) {
		setAnalyticsUserProperties({ token })
		const credentials = createCredentialsObject({ accountId, organizationId, token })

		return credentials ? { state: 'authenticated', credentials } : { state: 'unauthenticated' }
	}

	return userAuthStateInMainDomain()
}
