// @flow
import { useState, useEffect, useRef } from 'react'
import useSWR, { type keyInterface, type responseInterface, type ConfigInterface, cache } from 'swr'
import { fetcherFn } from 'swr/dist/types'
import { debounce } from 'throttle-debounce'

export default function useDebouncedSWR<Data = any, Error = any>(
	key: keyInterface,
	fn: fetcherFn<Data>,
	config?: ConfigInterface<Data, Error>,
	debounceTime: number = 1000,
): responseInterface<Data, Error> {
	const [serializedKey] = cache.serializeKey(key)
	const [localKey, setLocalKey] = useState<keyInterface>(key)
	const res = useSWR<Data, Error>(localKey, (...args) => fn(...args), config)
	const handleLocalKeyUpdate = useRef(debounce(debounceTime, setLocalKey))
	useEffect(() => {
		handleLocalKeyUpdate.current(key)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serializedKey])

	return res
}
