// @flow
import React from 'react'
import { Loading } from 'ui-common'

import './style.sass'

export default function LoadingLoginScreen(): React$Element<'div'> {
	return (
		<div className='LoginLoadingContainer'>
			<Loading width='100%' height='40rem' />
			<Loading width='100%' height='50rem' />
		</div>
	)
}
