// @flow
import React, { useState, useEffect, useCallback } from 'react'
import cx from 'classnames'

import useSWR from 'swr'
import { fetchBanner } from 'api/banner'
import type { AxiosResponseType, CommonError } from 'types/api/responses/common'
import type { BannerAPIResponse, BannerType } from 'types/api/responses/banner'

import markdown from 'logic/markdown'

import './styles.sass'

type BannerPresentType = {
	status: 'bannerPresent',
	banner: BannerType,
}

type BannerState =
	| {
			status: 'noBanner',
	  }
	| BannerPresentType

export default function Banner(): null | React$Element<'div'> {
	const { data } = useSWR<AxiosResponseType<BannerAPIResponse>, CommonError>('/api/banner', fetchBanner, {
		shouldRetryOnError: false,
	})
	const [bannerState, setBannerState] = useState<BannerState>({ status: 'noBanner' })
	useEffect(() => {
		if (data && data.data.banners.length) return setBannerState({ status: 'bannerPresent', banner: data.data.banners[0] })
	}, [data])

	const classNames = useCallback(
		(banner: BannerType) =>
			cx('BannerContainer', {
				'BannerContainer--announcement': banner.banner_type === 'announcement',
				'BannerContainer--issue': banner.banner_type !== 'announcement',
			}),
		[],
	)
	return bannerState.status === 'bannerPresent' ? (
		<div
			data-testid={bannerState.banner.banner_type === 'announcement' ? 'banner-with-announcement' : 'banner-with-issue'}
			className={classNames(bannerState.banner)}>
			<p
				dangerouslySetInnerHTML={{
					__html: markdown.parse(bannerState.banner.content),
				}}></p>
		</div>
	) : null
}
