import React from 'react'
import { Loading } from 'ui-common'

import './loading.sass'

export default function LoadingSettings() {
	return (
		<div className='LoadingSettingsContainer'>
			<div className='LoadingSettingsContainer__Header'>
				<Loading variant='rect' width='30%' height='4.8rem' className='LoadingSettingsContainer__Header__Tabs' />
			</div>
			<div className='LoadingSettingsContainer__Body' height='80vh'>
				<Loading variant='rect' className='LoadingSettingsContainer__Body__Item' height='60vh' />
				<Loading variant='rect' className='LoadingSettingsContainer__Body__Item' height='60vh' />
			</div>
		</div>
	)
}
