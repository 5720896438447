// @flow
import React, { type Node } from 'react'

import useOrganizationOwner from 'hooks/data/useOrganizationOwner'

type Props = {
	children: Node,
}
export default function OrganizationOwnerWrapper({ children }: Props): React$Node | React$Element<React$FragmentType> {
	const ownerStatus = useOrganizationOwner()
	return ownerStatus === 'owner' ? children : <></>
}
