// @flow

import React from 'react'
import { Loading } from 'ui-common'

import './style.sass'

export default function LoadSignUp(): React$Element<'div'> {
	return (
		<div className='SignupContainer'>
			<Loading height='60rem' width='80%' className='RegistrationContainer' />
			<Loading height='30rem' width='100%' className='InfoContainer' />
		</div>
	)
}
