import React from 'react'
import cs from 'classnames'
import { IconAction } from 'ui-common/Icon'

import './style.sass'

export default function Chip({ children, className, callback, ...rest }) {
	const cx = cs('ChipContainer', className)
	return (
		<div className={cx}>
			{children}
			{callback && (
				<IconAction
					className='ChipContainer--delete'
					name='close'
					onClick={callback(rest)}
					aria-label='delete date'
					type='button'></IconAction>
			)}{' '}
		</div>
	)
}
