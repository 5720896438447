// @flow
import React, { type Node } from 'react'

import { trackExternalLinkEvent } from 'utils/analytics'

type Props = {
	to: string,
	onClick?: () => void,
	children: Node,
	eventCategory: string,
}
export default function ExternalLink(props: Props): React$Element<'a'> {
	const { to, eventCategory, ...rest } = props

	const handleOnClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
		event.persist()
		if (props.onClick) {
			props.onClick()
		}
		trackExternalLinkEvent({ link: event.currentTarget.href, eventCategory })
	}
	return (
		<a {...rest} href={to} onClick={handleOnClick} target='_blank' rel='noopener noreferrer'>
			{props.children}
		</a>
	)
}
