import React from 'react'
import { Loading } from 'ui-common'

import './style.sass'

export default function TableLoading({ number = 10 }) {
	return (
		<div>
			{[...new Array(number)].map((item, idx) => (
				<div key={idx}>
					<Loading variant='rect' className='LoadingTableRow' />
				</div>
			))}
		</div>
	)
}
