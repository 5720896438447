import React from 'react'
import cx from 'classnames'

import './style.sass'
import classNames from 'classnames'

const breakErrorTextOnLineBreak = text => {
	const Element = text && text.includes('\n') ? 'li' : 'p'
	return (
		text &&
		text.split('\n').map((p, i) => {
			return <Element key={i}>{p}</Element>
		})
	)
}

const ValidationError = ({ errors = {}, name = '' }) => {
	const cs = cx('ValidationError', {
		'ValidationError--visible': errors[name] && errors[name].message,
	})

	const error = errors[name] && errors[name].message

	// Text placeholder is being kept as a dummy value in order for validation error to be transitionable
	// Transition can't happen when there is no content to transition to
	return <small className={cs}>{breakErrorTextOnLineBreak(error) || 'Text placeholder'}</small>
}

export const Checkbox = React.forwardRef(
	({ name, disabled, label, errors = {}, formElementClasses, containerclasses, labelclasses, ...props }, ref) => {
		const containerClasses = cx('Checkbox__container', containerclasses)
		return (
			<div className='Checkbox'>
				<span className={containerClasses}>
					<input
						type='checkbox'
						name={name}
						disabled={disabled}
						aria-label={label}
						className='Checkbox__input'
						ref={ref}
						{...props}
					/>
					{label && (
						<label
							className={cx('Checkbox__label', labelclasses)}
							htmlFor={name}
							{...props}
							onClick={props.onChange || null}>
							{label}
						</label>
					)}
				</span>
				{Object.keys(errors).length > 0 && <ValidationError errors={errors} name={name} />}
			</div>
		)
	},
)

export const Switch = React.forwardRef(({ name, disabled, label, desc, errors = {}, className = '', ...props }, ref) => {
	const cs = cx('Switch__input', className)
	return (
		<div className='Switch'>
			<input
				type='checkbox'
				name={name}
				aria-label={label}
				disabled={disabled}
				className={cs}
				ref={ref}
				id={name}
				{...props}
			/>
			{label && (
				<label className='Switch__label' htmlFor={name}>
					{label}
				</label>
			)}
			{desc && <small className='Switch__desc'>{desc}</small>}
			{Object.keys(errors).length > 0 && <ValidationError errors={errors} name={name} />}
		</div>
	)
})

export const Radio = React.forwardRef(({ name, disabled, label, errors = {}, ...props }, ref) => {
	return (
		<div className='Radio'>
			<span className='Radio__container'>
				<input
					type='radio'
					name={name}
					disabled={disabled}
					className='Radio__input'
					ref={ref}
					id={props.value}
					{...props}
				/>
				{label && (
					<label className='Radio__label' htmlFor={props.value}>
						{label}
					</label>
				)}
			</span>
		</div>
	)
})

export const RadioGroup = ({ name, label, errors = {}, children }) => {
	return (
		<div className='RadioGroup FormElement'>
			{label && (
				<label htmlFor={name} className='FormLabel'>
					{label}
				</label>
			)}
			{children}
			{Object.keys(errors).length > 0 && <ValidationError errors={errors} name={name} />}
		</div>
	)
}

export const Input = React.forwardRef(
	({ className, disabled, fit, name, invalid, label, standalone, errors = {}, parentClassName = '', ...props }, ref) => {
		const cs = cx('Input', className, {
			'Input--disabled': disabled,
			'Input--fit': fit,
			'Input--invalid': invalid || errors[name],
			'Input--standalone': standalone,
		})

		const parentClass = cx('FormElement', parentClassName, {
			'FormElement--standalone': standalone,
		})
		return (
			<div className={parentClass}>
				{label && (
					<label htmlFor={name} className='FormLabel'>
						{label}
					</label>
				)}
				<input className={cs} aria-label={label} disabled={disabled} name={name} id={name} ref={ref} {...props} />
				{Object.keys(errors).length > 0 && <ValidationError errors={errors} name={name} />}
			</div>
		)
	},
)

export const Select = React.forwardRef(
	(
		{
			type,
			className,
			disabled,
			fit,
			name,
			invalid,
			label,
			children,
			errors = {},
			parentClassName = '',
			standalone,
			...props
		},
		ref,
	) => {
		const cs = cx('Select', className, {
			'Select--disabled': disabled,
			'Select--fit': fit,
			'Select--standalone': standalone,
			'Select--invalid': invalid || errors[name],
		})

		const parentClass = cx('FormElement', parentClassName, {
			'FormElement--standalone': standalone,
		})
		return (
			<div className={parentClass}>
				{label && (
					<label htmlFor={name} className='FormLabel'>
						{label}
					</label>
				)}
				<select className={cs} disabled={disabled} aria-label={label} name={name} id={name} ref={ref} {...props}>
					{children}
				</select>
				{Object.keys(errors).length > 0 && <ValidationError errors={errors} name={name} />}
			</div>
		)
	},
)

export const Textarea = React.forwardRef(
	({ type, className, disabled, fit, name, invalid, label, children, errors = {}, formElementClasses, ...props }, ref) => {
		const cs = cx('Textarea', className, {
			'Textarea--disabled': disabled,
			'Textarea--fit': fit,
			'Textarea--invalid': invalid || errors[name],
		})

		return (
			<div className={classNames('FormElement', formElementClasses)}>
				{label && (
					<label htmlFor={name} className='FormLabel'>
						{label}
					</label>
				)}
				<textarea aria-label={label} className={cs} disabled={disabled} name={name} ref={ref} {...props} id={name} />
				{Object.keys(errors).length > 0 && <ValidationError errors={errors} name={name} />}
			</div>
		)
	},
)
