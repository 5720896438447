// @flow

import React from 'react'
import './style.sass'

const SuccefullySentMark = (): React$Element<'div'> => {
	return (
		<div className='icon-positioning d-flex'>
			<div className='success-icon'>
				<div className='success-icon__tip'></div>
				<div className='success-icon__long'></div>
			</div>
		</div>
	)
}

export default SuccefullySentMark
