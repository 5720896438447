//@flow

const sms_excelfile_examples = {
	'en-US': {
		normal: 'normal_sms_sample.xlsx',
		customised_date: 'custom_date_sample.xlsx',
		personalised: 'custom_sms_sample.xlsx',
	},
	fr: {
		normal: 'normal_sms_exemple.xlsx',
		customised_date: 'exemple_de_date_personnalisee.xlsx',
		personalised: 'exemple_de_sms_personnalise.xlsx',
	},
	sw: {
		normal: 'sampuli_ya_sms_ya_kawaida.xlsx',
		customised_date: 'sampuli_ya_tarehe_maalum.xlsx',
		personalised: 'Sampuli_ya_ujumbe_uliobinafsishwa.xlsx',
	},
}

export { sms_excelfile_examples }
