import React, { createContext, useContext, useState } from 'react'

type Count = {
	smsInbox?: number
	smsSent?: number
	smsScheduled?: number
	smsAttachments?: number
	smsRecipients?: number
	contactList?: number
	contactGroups?: number
	contactBlockList?: number
	airtimeSent?: number
	airtimeScheduled?: number
	bulkDataSent?: number
	bulkDataScheduled?: number
	surveyListCount?: number
	billingTransactions?: number
	businessAccountsList?: number
	businessAccountsUsers?: number
	businessAccountsMessages?: number
}

type AppContextEntity = {
	count: Count
	setCount: any
}

const AppContext = createContext<AppContextEntity>({
	count: {},
	setCount: () => null,
})

export const useAppContext = (): AppContextEntity => useContext<AppContextEntity>(AppContext)

const AppProvider = ({ children }: { children: any }): any => {
	const [count, setCount] = useState({
		smsInbox: 0,
		smsDrafts: 0,
		smsSent: 0,
		smsScheduled: 0,
		smsPendingApproval: 0,
		smsAttachments: 0,
		smsRecipients: 0,
		contactList: 0,
		contactGroups: 0,
		contactBlockList: 0,
		airtimeSent: 0,
		airtimeScheduled: 0,
		bulkDataSent: 0,
		bulkDataScheduled: 0,
		surveyListCount: 0,
		billingTransactions: 0,
		businessAccountsList: 0,
		businessAccountsUsers: 0,
		businessAccountsMessages: 0,
	})
	return <AppContext.Provider value={{ count, setCount }}>{children}</AppContext.Provider>
}

export default AppProvider
