// @flow

const BLANK_POSITIVE = '0.00'
const BLANK_NEGATIVE = '-0.00'

function formatBalanceGreaterThanAThousandWithCommas(balance: string) {
	return balance.replace(/\d(?=(\d{3})+\.)/g, '$&,')
}
export default function formatMoney(balance: number | null): string {
	if (balance == null || balance === 0) return BLANK_POSITIVE
	const formattedBalance = formatBalanceGreaterThanAThousandWithCommas(balance.toFixed(2))
	if (formattedBalance === BLANK_POSITIVE || formattedBalance === BLANK_NEGATIVE) {
		return balance.toFixed(2)
	}

	if (parseInt(balance) < 10) {
		const balanceToFourPlaces = balance.toFixed(4)
		if (balanceToFourPlaces.toString().slice(-2) === '00') return balance.toFixed(2)
		return balanceToFourPlaces
	}
	return formattedBalance
}
