// @flow
import React, { type Node, useMemo } from 'react'
import { Link, type LocationShape } from 'react-router-dom'
import cx from 'classnames'
import { Icon, type IconNamesEnum } from '../Icon'

const getContent = (iconBefore?: IconNamesEnum, iconAfter?: IconNamesEnum, stroke?: string, children: Node) => {
	if (iconBefore || iconAfter) {
		return (
			<span className='Button__content'>
				{iconBefore && <Icon className='Button__icon--before' name={iconBefore} width={16} height={16} stroke={stroke} />}
				{children}
				{iconAfter && <Icon className='Button__icon--after' name={iconAfter} width={16} height={16} stroke={stroke} />}
			</span>
		)
	}

	return children
}

type ButtonProps = {
	className?: string,
	regular?: boolean,
	outline?: boolean,
	dark?: boolean,
	grey?: boolean,
	fit?: boolean,
	green?: boolean,
	blueBorder?: boolean,
	greyBorder?: boolean,
	to?: string,
	disabled?: boolean,
	href?: string,
	withSiblings?: boolean,
	iconBefore?: IconNamesEnum,
	iconAfter?: IconNamesEnum,
	stroke?: string,
	children: Node,
	[x: string]: any,
}

type ButtonType =
	| {
			variant: 'button',
	  }
	| {
			variant: 'routerLink',
			to: string | LocationShape,
	  }
	| {
			variant: 'externalLink',
			href: string,
	  }

export const Button = ({
	className,
	regular,
	outline,
	dark,
	grey,
	fit,
	green,
	blueBorder,
	greyBorder,
	disabled,
	withSiblings,
	iconBefore,
	iconAfter,
	stroke = '#ffffff',
	...props
}: ButtonProps):
	| React$Element<'a'>
	| React$Element<'button'>
	| React$Element<
			React$ComponentType<{
				+children?: React$Node,
				+className?: string,
				+replace?: boolean,
				+to: string | LocationShape,
				...
			}>,
	  > => {
	const componentType: ButtonType = useMemo(() => {
		if (props.to) {
			const link: string | LocationShape = props.to
			return { variant: 'routerLink', to: link }
		}
		if (props.href) return { variant: 'externalLink', href: props.href }

		return { variant: 'button' }
	}, [props.href, props.to])

	const cs = cx('Button', className, {
		'Button--regular': regular,
		'Button--outline': outline,
		'Button--dark': dark,
		'Button--grey': grey,
		'Button--green': green,
		'Button--disabled': disabled,
		'Button--with-siblings': withSiblings,
		'Button--link': props.href || props.to,
		'Button--with-icon': iconBefore || iconAfter,
		'Button--blue-border': blueBorder,
		'Button--grey-border': greyBorder,
		'Button--fit': fit,
	})
	if (componentType.variant === 'routerLink')
		return (
			<Link to={componentType.to} {...props} className={cs} disabled={disabled}>
				{getContent(iconBefore, iconAfter, stroke, props.children)}
			</Link>
		)
	if (componentType.variant === 'externalLink')
		return (
			<a href={componentType.href} {...props} className={cs} disabled={disabled}>
				{getContent(iconBefore, iconAfter, stroke, props.children)}
			</a>
		)
	return (
		<button {...props} className={cs} disabled={disabled}>
			{getContent(iconBefore, iconAfter, stroke, props.children)}
		</button>
	)
}

export default Button
