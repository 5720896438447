// @flow
// import snarkdown from 'snarkdown'
import xss from 'xss'
import { URL } from './regex'

function sanitizeHTML(html: string): string {
	const onTagAttr = (tag, name, value) => {
		if (tag === 'a' && name === 'href' && URL.test(value)) {
			return `href="${value}" rel="noopener noreferrer" target="_blank" data-testid="external-link"`
		}
	}
	const xssFilterOptions = {
		onTagAttr,
		whiteList: {
			a: [],
		},
		stripIgnoreTag: true,
		stripIgnoreTagBody: ['script'],
	}
	const myXSS = new xss.FilterXSS(xssFilterOptions)
	return myXSS.process(html)
}
function parse(markdown: string): string {
	// const html = snarkdown(markdown)
	return sanitizeHTML(markdown)
}

const methods = {
	parse,
}

export default methods
