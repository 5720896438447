//@flow
import React from 'react'
type TextProps = {
	content: string,
}

const URL_REGEX =
	'(https?://(?:www.|(?!www))[a-zA-Z0][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'

export default function FormattedText({ content }: TextProps): React$Element<'p'> {
	const words = content.split(' ')
	return (
		<p>
			{words.map(word => {
				return word.match(URL_REGEX) ? (
					<>
						<a href={word}>{word}</a>{' '}
					</>
				) : (
					word + ' '
				)
			})}
		</p>
	)
}
