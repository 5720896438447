// @flow
import React, { type Node, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'

import cs from 'classnames'
import { IconAction } from 'ui-common'
import './style.sass'

const keyCodes = {
	ESC: 27,
}
type ModalProps = {
	children: Node,
	right?: boolean,
	wide?: boolean,
	open: boolean,
	handleClose: () => void,
}
export const Modal = ({ children, right, wide, open, handleClose }: ModalProps): React$Element<any> => {
	const contentClassName = cs('Modal__content', {
		'Modal__content--rightAlign': right,
		'Modal__content--wide': wide,
	})

	const activeElement = useRef()

	useEffect(() => {
		document.addEventListener('keydown', checkKeyDown)
		function checkKeyDown(e: KeyboardEvent) {
			if (open) {
				if (e.keyCode === keyCodes.ESC) {
					handleClose()
				}
			}
		}
		return () => document.removeEventListener('keydown', checkKeyDown)
	}, [open, handleClose])

	useEffect(() => {
		if (open) {
			activeElement.current = document.activeElement
		} else {
			if (activeElement.current) {
				activeElement.current.focus()
			}
		}
	}, [open])
	return (
		<AnimatePresence>
			{open ? (
				<div className='Modal' id='ModalContainer'>
					<motion.div
						className={contentClassName}
						tabIndex={0}
						id='Modal__Content'
						initial={{ y: 300, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { ease: [0.43, 0.13, 0.23, 0.96] } }}
						exit={{ y: 300, opacity: 0 }}>
						<div
							role='dialog'
							className='dialog'
							aria-labelledby='Dialog__Title'
							aria-describedby='Modal__Content'
							aria-modal='true'>
							{children}
						</div>
					</motion.div>
				</div>
			) : null}
		</AnimatePresence>
	)
}

export const ModalText = ({ children }: { children: Node }): React$Element<'div'> => <div className='Modal__text'>{children}</div>
export const ModalTitle = ({ children, className = '' }: { children: Node, className?: string }): React$Element<'h1'> => (
	<h1 className={cs('Modal__title', className)} id='Dialog__Title'>
		{children}
	</h1>
)

export const ModalHeader = ({ close, title }: { close: () => void, title: Node }): React$Element<React$FragmentType> => {
	const { t } = useTranslation(['ui-common/modal'])
	return (
		<>
			<div className='Header__Container'>
				<h1 className='Header__Container__Title' id='Dialog__Title'>
					{title}
				</h1>
				<IconAction
					onClick={close}
					name='close'
					aria-label={t('closeModal')}
					className='Header__Container__Icon'></IconAction>
			</div>
			<hr className='Header__Container__divider' />
		</>
	)
}
