// @flow

import Request from '../request'
import type { AxiosResponseType } from 'types/api/responses/common'
import type { UserProfileResponseType, OrganizationProfileResponse } from 'types/api/responses/profiles/'
import type { UserProfileRequestFormType, UserLanguageType } from 'types/api/requests/profile'
import type { SupportedLanguagesResponse } from 'types/api/responses/supportedLanguages'
import type { SupportedRolesResponse } from 'types/api/responses/enterprise'

export const getUserProfile = ({ user_id }: { user_id: number }): Promise<AxiosResponseType<UserProfileResponseType>> => {
	return Request.get({
		url: `/users/${user_id}`,
	})
}

export const updateUserProfile = ({
	user_id,
	data,
}: {
	user_id: number,
	data: UserProfileRequestFormType,
}): Promise<AxiosResponseType<UserProfileResponseType>> => {
	return Request.put({
		url: `/users/${user_id}`,
		data,
	})
}
export const updateUserLanguage = ({
	user_id,
	data,
}: {
	user_id: number,
	data: UserLanguageType,
}): Promise<AxiosResponseType<UserProfileResponseType>> => {
	return Request.put({
		url: `/users/${user_id}`,
		data,
	})
}
export const getOrganizationProfile = ({ uuid }: { uuid: string }): Promise<AxiosResponseType<OrganizationProfileResponse>> => {
	return Request.get({
		url: `/attachments/${uuid}/organization_profile`,
	})
}

export function updateContactInfo({
	id,
	data,
}: {
	id: number,
	data: { email: null | string, phone: null | string },
}): Promise<AxiosResponseType<UserProfileResponseType>> {
	return Request.put({
		url: `/users/${id}/contact-information`,
		data,
	})
}

export function updateTwoFactorAuth({ id, two_factor_auth_enabled }: { id: number, two_factor_auth_enabled: boolean }): Promise<any> {
	return Request.put({
		url: `/users/${id}`,
		data: { two_factor_auth_enabled },
	})
}

export function getSupportedLanguages(): Promise<AxiosResponseType<SupportedLanguagesResponse>> {
	return Request.get({
		url: `/supported-languages`,
	})
}

export function getUserRoles({ accountId }: { accountId: number }): Promise<AxiosResponseType<SupportedRolesResponse>> {
	return Request.get({
		url: `/account/${accountId}/supported_account_user_roles`,
	})
}
