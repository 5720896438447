// @flow

import React from 'react'
import { Loading } from 'ui-common'

import './style.sass'

export default function APIKeysLoading(): React$Element<'div'> {
	return (
		<div className='APIKeysLoading__Container'>
			<div className='APIKeysLoading__Container__header'>
				<Loading width='50%' height='20px' />
			</div>
			<Loading width='100%' height='400px' />
		</div>
	)
}
