// @flow
import Request from '../request'

import type { AxiosResponseType } from '../../types/api/responses/common'

import type { UserAccountsResponse, CreateAccountResponse, UserRolesResponse } from '../../types/api/responses/accounts'
import type { createAccountRequest } from '../../types/api/requests/accounts'

export function FetchAccounts({
	user_id,
	headers,
	params,
}: {
	user_id: number,
	headers?: { 'x-smsleopard-token': string },
	params: { page: number, per: number, term: string },
}): Promise<AxiosResponseType<UserAccountsResponse>> {
	return Request.get({
		url: '/users/' + user_id + '/accounts',
		headers,
		params,
	})
}

export function createAccount({ data }: { data: createAccountRequest }): Promise<AxiosResponseType<CreateAccountResponse>> {
	return Request.post({
		url: '/accounts',
		data,
	})
}

export function FetchUserRoles({
	user_id,
	accountId,
}: {
	user_id: number,
	accountId: number,
}): Promise<AxiosResponseType<UserRolesResponse>> {
	return Request.get({
		url: `/account/${accountId}/user/${user_id}/account_user`,
	})
}
