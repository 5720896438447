// @flow
import { useRef } from 'react'
import useSWR from 'swr'

import { viewOrganizationDetails } from 'api/enterprise'

import { type Organization } from 'types/api/responses/enterprise'
import type { AxiosResponseType, CommonError } from 'types/api/responses/common'

export default function useOrganization(): { data: void | AxiosResponseType<Organization>, error: void | CommonError, ... } {
	const { current: organizationId } = useRef(localStorage.getItem('x-smsleopard-organization-id'))
	const { data, error } = useSWR<AxiosResponseType<Organization>, CommonError>(
		organizationId ? [`organization/${organizationId}`, Number(organizationId)] : null,
		(url: string, organizationId: number) => viewOrganizationDetails({ organizationId }),
	)

	return {
		data,
		error,
	}
}
