// @flow
import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import './autoselect.sass'

type Suggestion = {
	id: number | string,
	name: string,
	category?: 'group' | 'contact' | 'custom',
	disabled?: boolean,
}

const AutoCompleteSearch = ({
	handleInputChange,
	suggestions,
	selectedValue,
	setSelectedValue,
}: {
	handleInputChange: (value: string) => void,
	suggestions: Suggestion[],
	setSelectedValue: (Suggestion[]) => void,
	selectedValue: Suggestion[],
}): React.Element<'div'> => {
	const handleSearchChange = (event: any) => {
		const value = event.target.value
		if (value.length >= 2) {
			handleInputChange(value)
		}
	}

	const handleAutocompleteChange = (event: any, value: Suggestion[]) => {
		if (typeof value[value.length - 1] === 'string') return
		const newUnique = value.filter((item, index, self) => {
			return (
				index ===
				self.findIndex(t => {
					return t.name === item.name
				})
			)
		})
		setSelectedValue(newUnique)
	}

	return (
		<StyledEngineProvider injectFirst>
			<Autocomplete
				id='autocomplete-input'
				multiple
				autoHighlight={true}
				options={suggestions}
				getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
				freeSolo
				filterSelectedOptions={true}
				onInputChange={handleSearchChange}
				onChange={handleAutocompleteChange}
				renderInput={customTextField}
				value={selectedValue}
			/>
		</StyledEngineProvider>
	)
}

type AutocompleteRenderInputParamsp = {}
const customTextField = (params: AutocompleteRenderInputParamsp) => {
	return <TextField {...params} variant='outlined' id='contact-input' />
}

export default AutoCompleteSearch
