import React from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

export type Step = {
	id: string
	name: string
	to: string
	status: 'complete' | 'current' | 'upcoming'
}

export default function Stepper({ steps }: { steps: Step[] }) {
	return (
		<nav aria-label='Progress'>
			<ol className='divide-y border rounded-md md:flex md:divide-y-0'>
				{steps.map((step, stepIdx) => (
					<li key={step.name} className='relative md:flex md:flex-1'>
						{step.status === 'complete' ? (
							<Link to={step.to} className='group flex w-full items-center'>
								<span className='flex items-center px-[2.4rem] py-[1.6rem] text-[1.4rem] font-medium'>
									<span className='flex h-[4rem] w-[4rem] flex-shrink-0 items-center justify-center rounded-full bg-primary group-hover:bg-primary/90'>
										<CheckIcon className='h-[2.4rem] w-[2.4rem] text-white' aria-hidden='true' />
									</span>
									<span className='ml-[1.6rem] text-[1.4rem] font-medium text-gray-900'>{step.name}</span>
								</span>
							</Link>
						) : step.status === 'current' ? (
							<Link
								to={step.to}
								className='flex items-center px-[2.4rem] py-[1.6rem] text-[1.4rem] font-medium'
								aria-current='step'>
								<span className='flex h-[4rem] w-[4rem] flex-shrink-0 items-center justify-center rounded-full border-2 border-primary'>
									<span className='text-primary'>{step.id}</span>
								</span>
								<span className='ml-[1.6rem] text-[1.4rem] font-medium text-primary'>{step.name}</span>
							</Link>
						) : (
							<Link to={step.to} className='group flex items-center'>
								<span className='flex items-center px-[2.4rem] py-[1.6rem] text-[1.4rem] font-medium'>
									<span className='flex h-[4rem] w-[4rem] flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400'>
										<span className='text-gray-500 group-hover:text-gray-900'>{step.id}</span>
									</span>
									<span className='ml-[1.6rem] text-[1.4rem] font-medium text-gray-500 group-hover:text-gray-900'>
										{step.name}
									</span>
								</span>
							</Link>
						)}

						{stepIdx !== steps.length - 1 ? (
							<>
								{/* Arrow separator for lg screens and up */}
								<div className='absolute right-0 top-0 hidden h-full w-5 md:block' aria-hidden='true'>
									<svg
										className='h-full w-full text-gray-300'
										viewBox='0 0 22 80'
										fill='none'
										preserveAspectRatio='none'>
										<path
											d='M0 -2L20 40L0 82'
											vectorEffect='non-scaling-stroke'
											stroke='currentcolor'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</>
						) : null}
					</li>
				))}
			</ol>
		</nav>
	)
}
