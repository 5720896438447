// @flow
import React, { Children, type Node } from 'react'
import Margin from '../Margin'

type Props = {
	space: number,
	units?: string,
	children: Node,
}

export default function StackComponent({ children, units, space }: Props): React$Element<'div'> {
	return (
		<div>
			{Children.map(children, child => (
				<Margin units={units} space={space}>
					{child}
				</Margin>
			))}
		</div>
	)
}
