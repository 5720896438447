import React from 'react'
import { Loading } from 'ui-common'

import './style.sass'
function LoadingFiles() {
	return (
		<div className='AttachmentsContainer'>
			<Loading width='100%' height='400px' className='AttachmentsContainer__Illustration'></Loading>
			<Loading width='100%' height='400px' className='AttachmentsContainer__Content'></Loading>
		</div>
	)
}

export default LoadingFiles
