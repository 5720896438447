// @flow
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { useEffect } from 'react'
import type { credentials } from 'types/auth'
import { Integrations as BrowserIntegrations } from '@sentry/browser'

type UseSentryUserProps = {
	credentials: credentials | null,
}
export function useSentryUser(props: UseSentryUserProps) {
	const { credentials } = props
	useEffect(() => {
		if (credentials) {
			return Sentry.setUser({
				id: credentials.userId.value,
				accountId: credentials.accountId.value,
				organizationId: credentials.organizationId.value,
			})
		}
		Sentry.setUser(null)
	}, [credentials])
}

export function setUpSentry() {
	const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS || null
	const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
	if (SENTRY_DNS && SENTRY_RELEASE)
		Sentry.init({
			release: SENTRY_RELEASE,
			dsn: SENTRY_DNS,
			integrations: [new Integrations.BrowserTracing(), new BrowserIntegrations.Dedupe()],
			tracesSampleRate: 1.0,
		})
}
