// @flow
import React, { useRef } from 'react'
import ReactTags from 'react-tag-autocomplete'
import './style.sass'

type Tag = {
	name: string,
	id: number,
	[prop: string]: any,
}

type Props = {
	placeholderText: string,
	ariaLabelText: string,
	tags: Tag[],
	label: string,
	suggestions: Tag[],
	onDelete: (i: number) => void,
	onAddition: (tag: Tag) => void,
	onInput: (query: string) => void,
	minQueryLength?: number,
}
export default function TagComponent(props: Props): React$Element<'div'> {
	const reactTagsRef = useRef()
	const { placeholderText, ariaLabelText, tags, suggestions, onDelete, onAddition, onInput, label, minQueryLength = 1 } = props
	return (
		<div className='TagsComponent__Container'>
			<p className='TagsComponent__Container__Label'>{label}</p>
			<ReactTags
				ariaLabelText={ariaLabelText}
				placeholderText={placeholderText}
				ref={reactTagsRef}
				tags={tags}
				suggestions={suggestions}
				onDelete={onDelete}
				onAddition={onAddition}
				onInput={onInput}
				minQueryLength={minQueryLength}
			/>
		</div>
	)
}
