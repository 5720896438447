// @flow
import React, { type Node, useMemo } from 'react'
import cs from 'classnames'
import './index.sass'

type GridProps = {
	className?: string,
	children: Node,
	size?: number,
}

export function GridItem(props: GridProps): React$Element<'div'> {
	const { children, size = 12, className = '' } = props
	const style = useMemo(() => {
		const width = `${Math.round((size / 12) * 10e7) / 10e5}%`
		return {
			flexBasis: width,
			flexGrow: 0,
			maxWidth: width,
		}
	}, [size])
	const cx = cs('Grid__Item', className)
	return (
		<div className={cx} style={style}>
			{children}
		</div>
	)
}

type GridContainerProps = {
	children: Node,
	className?: string,
}

export function GridContainer(props: GridContainerProps): React$Element<'div'> {
	const { children, className = '' } = props
	const cx = cs('Grid__Container', className)
	return <div className={cx}>{children}</div>
}
