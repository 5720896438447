// @flow
import { useRef, useState, useEffect, useCallback } from 'react'
import useSWR, { type mutateCallback } from 'swr'
import parseToken from 'logic/parseToken'
import { getUserProfile } from 'api/profiles'

// types
import type { AxiosResponseType, CommonError } from 'types/api/responses/common'
import type { UserProfileResponseType } from 'types/api/responses/profiles/'
import type { GenericDetailsStateMachine } from 'types/api/common'

export default function useProfileData(): {
	mutateUserProfile: (
		data?:
			| AxiosResponseType<UserProfileResponseType>
			| mutateCallback<AxiosResponseType<UserProfileResponseType>>
			| Promise<AxiosResponseType<UserProfileResponseType>>,
		shouldRevalidate?: boolean,
	) => Promise<void | AxiosResponseType<UserProfileResponseType>>,
	userProfileData: void | AxiosResponseType<UserProfileResponseType>,
	userProfileError: void | CommonError,
	...
} {
	const { current: parsedToken } = useRef(parseToken())

	const { data: userProfileData, error: userProfileError, mutate: mutateUserProfile } = useSWR<
		AxiosResponseType<UserProfileResponseType>,
		CommonError,
	>(parsedToken ? [`/users/${parsedToken.user_id}`, parsedToken.user_id] : null, (url: string, user_id: number) =>
		getUserProfile({ user_id }),
	)

	return {
		userProfileData,
		userProfileError,
		mutateUserProfile,
	}
}

export function useProfileState(): {
	profileState: GenericDetailsStateMachine<UserProfileResponseType>,
	refreshProfile: () => void,
	...
} {
	const [profileState, setProfileState] = useState<GenericDetailsStateMachine<UserProfileResponseType>>({ status: 'idle' })
	const { userProfileData, userProfileError, mutateUserProfile } = useProfileData()

	useEffect(() => {
		if (userProfileData) {
			return setProfileState({ status: 'resolved', data: userProfileData.data })
		}
		if (userProfileError) {
			return setProfileState({ status: 'rejected' })
		}
		if (!userProfileData && !userProfileError) {
			return setProfileState({ status: 'pending' })
		}
	}, [userProfileData, userProfileError])

	const refreshProfile = useCallback(() => {
		mutateUserProfile()
	}, [mutateUserProfile])

	return {
		profileState,
		refreshProfile,
	}
}
