import React, { useEffect, useState } from 'react'
import { useSurveyContext } from '../views/SurveyQuestions/SurveyProvider'
import { ArrowPathIcon, PaperAirplaneIcon, TrashIcon, UserIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'
import { sendSimulatorResponse } from 'api/surveys'

type Message = {
	question_id?: number
	sender: 'user' | 'simulator' | 'info'
	response: string
}

const SurveySimulator = () => {
	const { surveyDetails, setErrorMessage } = useSurveyContext()

	const initialText = `Welcome to the Survey Simulator. It's free to use, and responses won't be saved. Click the Reset button at the top right whenever you want to start over.`

	const defaultMessages: Message[] = [
		{
			response: initialText,
			sender: 'simulator',
		},
		{
			response: `Reply with ${surveyDetails.keyword.toUpperCase()} to participate.`,
			sender: 'simulator',
		},
	]

	function generateRandomPhoneNumber() {
		const random8digitNumber = Math.floor(10000000 + Math.random() * 90000000)
		const phoneNumberPrefix = '+2547'
		return phoneNumberPrefix + random8digitNumber
	}

	const [messages, setMessages] = useState<Message[]>(defaultMessages)
	const [phoneNumber, setPhoneNumber] = useState(generateRandomPhoneNumber())

	useEffect(() => {
		const messages = document.getElementById('messages')
		if (messages) {
			messages.scrollTop = messages.scrollHeight
		}
	}, [messages])

	const playNotificationSound = () => {
		const audio = new Audio('/sounds/happy-pop-3.mp3')
		audio.play()
	}

	const [inputValue, setInputValue] = useState('')

	const handleSendMessage = async () => {
		if (inputValue) {
			setMessages(initialMessages => [
				...initialMessages,
				{
					response: inputValue,
					sender: 'user',
				},
			])
			setInputValue('')
		}

		const previousMessage = messages[messages.length - 1]

		try {
			const response = await sendSimulatorResponse({
				question_id: previousMessage.question_id,
				response: inputValue,
				short_code_id: surveyDetails.short_code_id,
				phone_number: phoneNumber,
			})
			if (response.data.survey_question) {
				setMessages(initialMessages => [
					...initialMessages,
					{
						question_id: response.data.survey_question.id,
						response: response.data.survey_question.question,
						sender: 'simulator',
					},
				])
				playNotificationSound()
				return
			}
			if (response.data.survey_completion_message) {
				setMessages(initialMessages => [
					...initialMessages,
					{
						response: response.data.survey_completion_message.message,
						sender: 'simulator',
					},
					{
						response: '-- End of survey --',
						sender: 'info',
					},
				])
				playNotificationSound()
				return
			}
			setMessages(initialMessages => [
				...initialMessages,
				{
					response: '-- End of survey --',
					sender: 'info',
				},
				{
					response: 'Add a completion message in the survey outro section to display an end message',
					sender: 'info',
				},
			])
		} catch (error) {
			if (error.status === 404) {
				setMessages(initialMessages => [
					...initialMessages,
					{
						response: `-- Participants will need to reply with the keyword ${surveyDetails.keyword.toUpperCase()} to participate. You can change this in survey details --`,
						sender: 'info',
					},
				])
			} else {
				setErrorMessage(error.errorMessage)
			}
		}
	}

	const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSendMessage()
		}
	}

	const handleResetChat = () => {
		setMessages([])
		const phoneNumber = generateRandomPhoneNumber()
		setPhoneNumber(phoneNumber)
		setTimeout(() => {
			setMessages([defaultMessages[1]])
			playNotificationSound()
		}, 500)
	}

	const handleClearResponsesFromIdx = (idx: number) => {
		messages.length = idx
		setMessages([...messages])
	}

	return (
		<>
			<style>
				{`
				.scrollbar-w-2::-webkit-scrollbar {
				  width: 0.25rem;
				  height: 0.25rem;
				}
				.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
				  --bg-opacity: 1;
				  background-color: #f7fafc;
				  background-color: rgba(247, 250, 252, var(--bg-opacity));
				}
				.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
				  --bg-opacity: 1;
				  background-color: #edf2f7;
				  background-color: rgba(237, 242, 247, var(--bg-opacity));
				}
				.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
				  border-radius: 0.25rem;
				}`}
			</style>
			<div className='flex-1 sm:px-[1.5rem]  justify-between flex flex-col bg-white rounded-2xl overflow-hidden border'>
				<div className='flex sm:items-center justify-between py-[1rem] border-b-2 border-gray-100'>
					<div className='relative flex items-center space-x-4'>
						<div className='relative border rounded-full p-3'>
							<UserIcon className='h-10' />
						</div>
						<div className='mt-1'>
							{surveyDetails.short_code ? (
								<span className='text-2xl font-semibold text-gray-700 mr-3'>{surveyDetails.short_code}</span>
							) : (
								<span className='bg-yellow-100 text-yellow-600 text-lg py-2 px-5 rounded-lg font-semibold'>
									Short-code not assigned
								</span>
							)}
						</div>
					</div>
					<div className='flex items-center space-x-2'>
						<button
							title='Reset chat'
							type='button'
							onClick={handleResetChat}
							className='inline-flex items-center justify-center rounded-lg border h-[4rem] w-[4rem] transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none'>
							<ArrowPathIcon className='h-8 w-8' />
						</button>
					</div>
				</div>
				<div
					id='messages'
					className='flex flex-col h-[40.6rem] space-y-[1.6rem] p-[1.2rem] overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch'>
					{messages.map((message, idx) => (
						<ChatMessage
							key={idx}
							message={message}
							handleClearResponsesFromIdx={() => handleClearResponsesFromIdx(idx)}
						/>
					))}
				</div>
				<div className='border-t-2 border-gray-100 px-4 py-[1rem] mb-[0.8rem] sm:mb-0'>
					<div className='relative flex'>
						<input
							type='text'
							placeholder='Text message'
							value={inputValue}
							onChange={e => setInputValue(e.target.value)}
							onKeyDown={handleEnterKey}
							className='w-full focus:outline-none focus:ring-0 border-1 text-xl border-gray-200 focus:placeholder-gray-400 text-gray-600 placeholder-gray-500 pl-[2rem] bg-gray-100 rounded-md py-[1.2rem]'
						/>
						<button onClick={handleSendMessage}>
							<PaperAirplaneIcon className='h-10 w-10 text-gray-400 hover:text-gray-500 absolute top-1/2 transform -translate-y-1/2 right-4' />
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default SurveySimulator

const ChatMessage = ({ message, handleClearResponsesFromIdx }: { message: Message; handleClearResponsesFromIdx: () => void }) => {
	return (
		<div className='chat-message'>
			<div
				className={cn('flex flex-col space-y-[0.8rem] text-[1.2rem] max-w-[32rem] mx-[0.8rem]', {
					'order-1 items-end': message.sender === 'user',
					'order-2 items-start': message.sender === 'simulator',
				})}>
				<div className='flex items-center gap-4'>
					{message.sender === 'user' && (
						<div
							className='p-2.5 rounded-full bg-gray-200 hover:bg-gray-300 cursor-pointer'
							onClick={handleClearResponsesFromIdx}
							title='Clear responses from here. Simulator only feature.'>
							<TrashIcon className='h-6 w-6 text-gray-400' />
						</div>
					)}

					<span
						className={cn('px-[1.6rem] py-[0.8rem] rounded-[0.8rem] inline-block whitespace-pre-line', {
							'rounded-br-none bg-blue-600 text-white': message.sender === 'user',
							'rounded-bl-none bg-gray-200 text-gray-700': message.sender === 'simulator',
							'italic text-lg text-center w-full': message.sender === 'info',
						})}>
						{message.response}
					</span>
				</div>
			</div>
		</div>
	)
}
