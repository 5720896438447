// @flow

import React, { type Node, useRef, useLayoutEffect } from 'react'
import cx from 'classnames'
import './style.sass'

type TabsProps = {
	children: Node,
	className?: string,
	containerClassName?: string,
	value: string,
	onChange: string => void,
	ariaLabel: string,
}

export const Tabs = ({
	children,
	className,
	containerClassName,
	value,
	onChange,
	ariaLabel,
}: TabsProps): React$Element<'div'> => {
	const containerclass: string = cx('Tabable', containerClassName)
	const tabs = useRef(document.querySelectorAll('[role="tab"]'))
	const cs: string = cx('Tabs', className)
	let tabFocus = useRef(0)

	const clonedChildren = React.Children.map(children, child =>
		React.cloneElement(child, {
			value: value,
			onChange: onChange,
		}),
	)

	useLayoutEffect(() => {
		if (tabs.current.length === 0) {
			tabs.current = document.querySelectorAll('[role="tab"]')

			for (let idx = 0; idx < tabs.current.length; idx++) {
				if (tabs.current[idx].id === value) {
					tabFocus.current = idx
					break
				}
			}
		}
	})

	const handleKeyDownEvent = (e: KeyboardEvent) => {
		// Move right
		if (e.keyCode === 39 || e.keyCode === 37) {
			tabs.current[tabFocus.current].setAttribute('tabindex', '-1')
			if (e.keyCode === 39) {
				tabFocus.current++
				// If we're at the end, go to the start
				if (tabFocus.current >= tabs.current.length) {
					tabFocus.current = 0
				}
				// Move left
			} else if (e.keyCode === 37) {
				tabFocus.current--
				// If we're at the start, move to the end
				if (tabFocus.current < 0) {
					tabFocus.current = tabs.current.length - 1
				}
			}

			tabs.current[tabFocus.current].setAttribute('tabindex', '0')
			tabs.current[tabFocus.current].focus()
		}
	}
	return (
		<div className={containerclass}>
			<div className={cs} role='tablist' aria-label={ariaLabel} onKeyDown={handleKeyDownEvent}>
				{clonedChildren}
			</div>
		</div>
	)
}

type SingleTabProps = {
	id: string,
	onChange?: string => void,
	className?: string,
	clear?: boolean,
	value?: string,
	rest?: {
		[string]: string,
	},
	children: Node,
}
export const Tab = ({ id, onChange, className, children, clear, value, ...rest }: SingleTabProps): React$Element<'button'> => {
	const cs: string = cx('Tab', className, { 'Tab--active': value === id, 'Tab--clear': Boolean(clear) })

	const onClick = () => {
		if (onChange) onChange(id)
	}

	return (
		<button
			{...rest}
			onClick={onClick}
			className={cs}
			role='tab'
			id={id}
			aria-selected={value === id}
			tabIndex={Number(value === id) - 1}>
			{children}
		</button>
	)
}

type TabPanelProps = {
	value: string,
	tabPanelValue: string,
	children: Node,
	variants?: Object,
}

export const TabPanel = ({ tabPanelValue, value, children }: TabPanelProps): React$Element<'div'> => {
	return (
		<div
			className='TabPanel__Container'
			role='tabpanel'
			tabIndex={Number(value === tabPanelValue) - 1}
			aria-labelledby={tabPanelValue}>
			{children}
		</div>
	)
}
