// @flow

import Request from '../request'
import type { AxiosResponseType } from '../../types/api/responses/common'
import type {
	FetchAccountUsers,
	FetchOrganizationUsers,
	OrganizationUpgradeResponse,
	Organization,
	AccountSummary,
	MessagesResponseObject,
	OrganizationAccountsResponse,
	AccountsItem,
	TransferTransactionResponse,
	AccountSenderIdsType,
	OrganizationUser,
	OrganizationBalance,
	OrganizationSenderIDListResponse,
} from '../../types/api/responses/enterprise'
import type {
	OrganizationUpgradeDetails,
	UploadOrganizationAvatarObject,
	OrganizationInvitationObject,
	AccountUserPayload,
	UpdateAccountType,
	TransferTransaction,
} from '../../types/api/requests/enterprise'

type Role = {
	account_user_roles: Array<string>,
}

export function getOrganizationUsers({
	organizationId,
	params,
}: {
	organizationId: number,
	params: Object,
}): Promise<AxiosResponseType<FetchOrganizationUsers>> {
	return Request.get({
		url: `/organizations/${organizationId}/organization_users`,
		params,
	})
}

export function upgradeOrganization({
	organizationId,
	data,
}: {
	organizationId: string,
	data: OrganizationUpgradeDetails,
}): Promise<AxiosResponseType<OrganizationUpgradeResponse>> {
	return Request.post({
		url: `/organizations/${organizationId}/upgrade`,
		data,
	})
}

export function InviteUser({
	data,
}: {
	data: OrganizationInvitationObject,
}): Promise<AxiosResponseType<OrganizationInvitationObject>> {
	return Request.post({
		url: `/organization_invites`,
		data,
	})
}

export function addAccountUser({ data }: { data: AccountUserPayload }): Promise<AxiosResponseType<AccountUserPayload>> {
	return Request.post({
		url: `/account_users`,
		data,
	})
}

export function updateAccountUser({
	accountId,
	userId,
	data,
}: {
	accountId: number,
	userId: number,
	data: Role,
}): Promise<AxiosResponseType<Organization>> {
	return Request.put({
		url: `/account/${accountId}/user/${userId}/account_users`,
		data,
	})
}

export function viewOrganizationDetails({
	organizationId,
}: {
	organizationId: number,
}): Promise<AxiosResponseType<Organization>> {
	return Request.get({
		url: `/organizations/${organizationId}`,
	})
}

export function updateOrganization({
	organizationID,
	data,
}: {
	organizationID: number,
	data: OrganizationUpgradeDetails,
}): Promise<AxiosResponseType<Organization>> {
	return Request.put({
		url: `/organizations/${organizationID}`,
		data,
	})
}

export function uploadOrganizationAvatar(data: UploadOrganizationAvatarObject): Promise<AxiosResponseType<Organization>> {
	return Request.post({
		url: `/organization_avatars`,
		data,
	})
}

export function fetchAccountSummary({
	dates,
	accountId,
}: {
	dates: { from: string | null, to: string | null },
	accountId: number,
}): Promise<AxiosResponseType<AccountSummary>> {
	return Request.get({
		url: `/accounts/${accountId}/summary`,
		params: dates,
	})
}

export function fetchOrganizationMessages({
	organizationId,
	params,
}: {
	organizationId: number,
	params: Object,
}): Promise<AxiosResponseType<MessagesResponseObject>> {
	return Request.get({
		url: `/organizations/${organizationId}/messages`,
		params,
	})
}

export function fetchOrganizationAccounts({
	organizationId,
	params,
}: {
	organizationId: number,
	params: Object,
}): Promise<AxiosResponseType<OrganizationAccountsResponse>> {
	return Request.get({
		url: `/organizations/${organizationId}/accounts`,
		params,
	})
}

export function toggleAccountStatus({
	accountId,
	action,
}: {
	accountId: number,
	action: 'activate' | 'deactivate',
}): Promise<AxiosResponseType<AccountsItem>> {
	return Request.post({
		url: `/accounts/${accountId}/${action}`,
	})
}

export function updateOrganizationAccount({
	data,
	accountId,
}: {
	data: UpdateAccountType,
	accountId: number,
}): Promise<AxiosResponseType<AccountsItem>> {
	return Request.put({
		url: `/accounts/${accountId}`,
		data,
	})
}

export function fetchAccountDetails({ accountId }: { accountId: number }): Promise<AxiosResponseType<AccountsItem>> {
	return Request.get({
		url: `/accounts/${accountId}`,
	})
}

export function transferAmount(data: TransferTransaction): Promise<AxiosResponseType<TransferTransactionResponse>> {
	return Request.post({
		url: `/transfer_transactions`,
		data,
	})
}

export function getAccountSenderIds({
	accountId,
	params,
}: {
	accountId: number,
	params: Object,
}): Promise<AxiosResponseType<AccountSenderIdsType>> {
	return Request.get({
		url: `/accounts/${accountId}/sender_ids`,
		params,
	})
}

export function getAccountUsers({
	accountId,
	params,
}: {
	accountId: number,
	params: Object,
}): Promise<AxiosResponseType<FetchAccountUsers>> {
	return Request.get({
		url: `/accounts/${accountId}/users`,
		params,
	})
}

export function removeUsersFromAccount({
	accountId,
	user_ids,
}: {
	accountId: number,
	user_ids: number[],
}): Promise<AxiosResponseType<{ success: boolean }>> {
	return Request.delete({
		url: `/accounts/${accountId}/users`,
		data: {
			user_ids,
		},
	})
}

export function removeUsersFromOrganization({
	organization_id,
	data,
}: {
	organization_id: number,
	data: { user_ids: number[] },
}): Promise<AxiosResponseType<{ success: boolean }>> {
	return Request.delete({
		url: `/organizations/${organization_id}/users?ids=${data.user_ids.join()}`,
	})
}

export function listUserAccountsInOrganization({
	organization_id,
	user_id,
	params,
}: {
	organization_id: number,
	user_id: number,
	params: Object,
}): Promise<AxiosResponseType<OrganizationAccountsResponse>> {
	return Request.get({
		url: `/organizations/${organization_id}/users/${user_id}/accounts`,
		params,
	})
}

export function getOrganizationUser({
	organizationId,
	userId,
}: {
	organizationId: number,
	userId: number,
}): Promise<AxiosResponseType<OrganizationUser>> {
	return Request.get({
		url: `/organizations/${organizationId}/users/${userId}`,
	})
}

export function updateUserAccountsInOrganization({
	organizationId,
	userId,
	data,
}: {
	organizationId: number,
	userId: number,
	data: {
		account_ids: number[],
	},
}): Promise<AxiosResponseType<any>> {
	return Request.put({
		url: `/organizations/${organizationId}/users/${userId}/accounts`,
		data,
	})
}

export function getOrganizationDetails({ organizationId }: { organizationId: number }): Promise<AxiosResponseType<Organization>> {
	return Request.get({
		url: `/organizations/${organizationId}`,
	})
}

export function getOrganizationBalance({
	organizationId,
}: {
	organizationId: number,
}): Promise<AxiosResponseType<OrganizationBalance>> {
	return Request.get({
		url: `/organizations/${organizationId}/balance`,
	})
}

export function getOrganizationSenderIDS({
	organizationId,
	params,
}: {
	organizationId: number,
	params: Object,
}): Promise<AxiosResponseType<OrganizationSenderIDListResponse>> {
	return Request.get({
		url: `/organizations/${organizationId}/organization_sender_ids`,
		params,
	})
}

export function assignSenderIDToAccount({
	organization_sender_id_ids,
	account_id,
}: {
	organization_sender_id_ids: number[],
	account_id: number,
}): Promise<AxiosResponseType<Object>> {
	return Request.post({
		url: `/account_sender_ids`,
		data: {
			organization_sender_id_ids,
			account_id,
		},
	})
}

export function deassignSenderIDSFromAccount({
	organization_sender_id_ids,
	account_id,
}: {
	organization_sender_id_ids: number[],
	account_id: number,
}): Promise<AxiosResponseType<Object>> {
	return Request.delete({
		url: `/accounts/${account_id}/sender_ids?ids=${organization_sender_id_ids.join()}`,
	})
}
