// @flow
import { useState, useEffect } from 'react'
import useSWR from 'swr'

import { getCountryCodeByIp } from 'api/auth'

import type { CommonError, AxiosResponseType } from 'types/api/responses/common'
import type { Country } from 'types/api/responses/countries'

type SystemState = 'active' | 'underMaintenance'

export default function useSystemsState(): SystemState {
	const [systemState, setSystemState] = useState<SystemState>('active')
	const { error } = useSWR<AxiosResponseType<Country>, CommonError>([`/countries/country-by-ip`], () => getCountryCodeByIp())

	const isSystemUnderMaintainance = (error: CommonError) => {
		return Boolean(error.error_code && error.error_code === 'maintenance_mode')
	}
	useEffect(() => {
		if (error && isSystemUnderMaintainance(error)) {
			setSystemState(() => 'underMaintenance')
		}
	}, [error])

	return systemState
}
