// @flow
import { useMemo, useRef, useEffect } from 'react'
import { useImmer } from 'use-immer'
import useSWR, { type mutateCallback } from 'swr'
import defaultPaginationParams from 'data-provider'

// logic
import parseToken from 'logic/parseToken'

// api
import { FetchAccounts } from 'api/accounts'

// type
import type { UserAccountsResponse } from 'types/api/responses/accounts'
import type { AxiosResponseType, CommonError } from 'types/api/responses/common'
import type { PaginationParams } from 'types/api/requests/common'

export default function useUserAccounts(): {
	data: void | AxiosResponseType<UserAccountsResponse>,
	error: void | CommonError,
	isLoading: boolean,
	mutate: (
		data?:
			| AxiosResponseType<UserAccountsResponse>
			| mutateCallback<AxiosResponseType<UserAccountsResponse>>
			| Promise<AxiosResponseType<UserAccountsResponse>>,
		shouldRevalidate?: boolean,
	) => Promise<void | AxiosResponseType<UserAccountsResponse>>,
	updateParams: ((draft: PaginationParams) => PaginationParams | void) => void,
	params: PaginationParams,
	...
} {
	const { current: currentToken } = useRef(localStorage.getItem('x-smsleopard-token'))
	const parsedToken = useMemo(() => parseToken(), [])
	const [params, updateParams] = useImmer<PaginationParams>({ ...defaultPaginationParams, per: 100 })
	const { data, error, mutate } = useSWR<AxiosResponseType<UserAccountsResponse>, CommonError>(
		parsedToken && [
			'/users/' + parsedToken.user_id + '/accounts',
			parsedToken.user_id,
			currentToken,
			params.page,
			params.per,
			params.term,
		],
		(url: string, user_id: number, currentToken: string) =>
			FetchAccounts({
				user_id: user_id,
				headers: { 'x-smsleopard-token': currentToken },
				params,
			}),
	)
	useEffect(() => {
		if (data) {
			updateParams(draft => ({ ...draft, ...data.data.pagination }))
		}
	}, [data, updateParams])

	return {
		data,
		error,
		mutate,
		isLoading: !data && !error,
		updateParams,
		params,
	}
}
