// @flow
import React, { useEffect, useMemo, useState } from 'react'
import useCustomMediaQuery from 'hooks/useCustomMediaQuery'
import { useHistory, useParams } from 'react-router-dom'
import { Select, Tab, Tabs } from 'ui-common'
import formatNumber from 'logic/shortenNumber'
import { useTranslation } from 'react-i18next'
import { getSurveyDetails } from 'api/surveys'
import defaultPaginationParams from 'data-provider'
import useQuestions from '../useQuestions'

import type { PaginationParams } from 'types/api/requests/common'
import { useSurveyContext } from './SurveyQuestions/SurveyProvider'
type props = {
	surveyID: number,
	setErrorMessage: (message: string) => void,
}

const startParams: PaginationParams = JSON.parse(JSON.stringify(defaultPaginationParams))
startParams.per = 80

export default function SurveyNav({ surveyID, setErrorMessage }: props): React$Element<'div'> {
	const params = useParams()
	const view = params['0'] || 'details'
	const { questions } = useSurveyContext()
	const { t } = useTranslation(['surveys/survey-details-header'])
	const { data } = useQuestions({ params: startParams, surveyID })
	const isMobile = useCustomMediaQuery(520, 'max')
	const [surveyStatus, setSurveyStatus] = useState<string>('idle')

	const isPublished: boolean = surveyStatus === 'inactive' || surveyStatus === 'published' ? true : false
	const noQuestion: boolean = data?.data.pagination.count === 0

	const history = useHistory()

	const routes = {
		details: `/surveys/${surveyID}`,
		questions: `/surveys/${surveyID}/questions`,
		responses: `/surveys/${surveyID}/responses`,
		send: `/surveys/${surveyID}/send`,
		outro: `/surveys/${surveyID}/outro`,
	}

	useEffect(() => {
		const getSurveyStatus = async (id: number): Promise<void> => {
			await getSurveyDetails(surveyID)
				.then(survey => {
					setSurveyStatus(survey.data.status)
				})
				.catch(err => setErrorMessage(err.message))
		}
		if (surveyID) {
			getSurveyStatus(surveyID)
		}
	}, [surveyID, setErrorMessage])

	const handleSelectChange = (event: { target: { value: string } }) => {
		const view = event.target.value
		const route = routes[view]
		if (route && surveyID) {
			if (view === 'send' && noQuestion) {
				setErrorMessage(t('errors.noQuestion'))
				return
			}
			history.push(route)
		}
	}

	const handleTabsChange = (value: string) => {
		const route = routes[value]
		if (route && surveyID) {
			if (value === 'send' && noQuestion) {
				setErrorMessage(t('errors.noQuestion'))
				return
			}
			history.push(route)
		}
	}

	const numberOfAddedQuestions = useMemo(() => {
		return questions.filter(question => question?.id).length
	}, [questions])

	return (
		<div className='SurveyNavContainer'>
			{isMobile ? (
				<div className='SelectContainer'>
					<Select
						parentClassName='SelectContainer__Mobile__select'
						className='SelectContainer__Select'
						fit
						standalone
						value={view}
						name='screens'
						onChange={handleSelectChange}>
						<option value='details'>{t('surveyDetails')}</option>
						<option value='questions'>{t('surveyQuestions', { count: formatNumber(numberOfAddedQuestions) })}</option>
						<option value='outro'>Survey Outro</option>
						{isPublished ? (
							<option value='responses'>{t('surveyResponses')}</option>
						) : (
							<option value='send'>{t('sendSurvey')}</option>
						)}
					</Select>
				</div>
			) : (
				<div className='bg-white w-max'>
					<Tabs ariaLabel='Survey tabs' value={view || ''} onChange={handleTabsChange}>
						<Tab clear id='details'>
							{t('surveyDetails')}
						</Tab>
						<Tab clear id='questions'>
							{isPublished
								? 'Question Stats'
								: t('surveyQuestions', { count: formatNumber(numberOfAddedQuestions) })}
						</Tab>
						{isPublished ? (
							<Tab clear id='responses'>
								Participants {t('surveyResponses')}
							</Tab>
						) : (
							<></>
						)}
						<Tab clear id='outro'>
							Survey Outro
						</Tab>
						{!isPublished ? (
							<Tab clear id='send'>
								{t('sendSurvey')}
							</Tab>
						) : (
							<></>
						)}
					</Tabs>
				</div>
			)}
		</div>
	)
}
