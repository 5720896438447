// @flow

import useSWR, { type mutateCallback } from 'swr'
import { getBalanceInAccount } from 'api/billing'

// types
import type { AxiosResponseType, CommonError } from 'types/api/responses/common'
import type { AccountBalance } from 'types/api/responses/billing'

type Props = {
	accountId: number,
}
type AccountBalanceResponse = AxiosResponseType<AccountBalance>
export default function useAccountBalance({
	accountId,
}: Props): {
	data: void | AccountBalanceResponse,
	error: void | CommonError,
	mutate: (
		data?: AccountBalanceResponse | mutateCallback<AccountBalanceResponse> | Promise<AccountBalanceResponse>,
		shouldRevalidate?: boolean,
	) => Promise<void | AccountBalanceResponse>,
	...
} {
	const { data, error, mutate } = useSWR<AccountBalanceResponse, CommonError>(
		[`/accounts/${accountId}/balance`, accountId],
		(url: string, accountId: number) => getBalanceInAccount({ accountId: Number(accountId) }),
	)

	return { data, error, mutate }
}
