// @flow
import React, { type Node, useMemo } from 'react'

type Props = {
	space: number,
	units?: string,
	children: Node,
}

export default function MarginComponent({ space, units = 'rem', children }: Props): Array<$NonMaybeType<Node>> {
	const style = useMemo(
		() => ({
			margin: space + units,
		}),
		[space, units],
	)
	return React.Children.map(children, child =>
		React.cloneElement(child, {
			style: style,
		}),
	)
}
