// @ts-nocheck
import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import './style.sass'
import Icon from '../Icon'

export const ArrowLink = ({ small, className, to, href, iconName = 'arrow-right', ...props }: any) => {
	let Tag = 'a'
	let linkTo = { href }

	if (to) {
		Tag = Link
		linkTo = { to }
	}

	const cs = cx('ArrowLink', className, { 'ArrowLink--small': small })

	return (
		<Tag className={cs} {...linkTo} {...props}>
			<span className='ArrowLink__content'>{props.children}</span>
			<Icon className='ArrowLink__arrow' name={iconName} width={16} height={16} />
		</Tag>
	)
}

export default ArrowLink
