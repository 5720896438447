import React, { useEffect, useState } from 'react'

import './styles.sass'

const Fade = ({ show, children, duration = 200 }) => {
	const [render, setRender] = useState(show)

	useEffect(() => {
		setRender(show)
	}, [show])

	const onAnimationEnd = () => {
		if (!show) setRender(false)
	}

	return (
		render && (
			<div style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} ${duration}ms ease-in` }} onAnimationEnd={onAnimationEnd}>
				{children}
			</div>
		)
	)
}

export default Fade
