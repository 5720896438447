// @flow
import { ExampleDataProvider, withExampleData } from './example'
import { sms_excelfile_examples } from './linodefiles'

export { ExampleDataProvider, withExampleData, sms_excelfile_examples }

const defaultPaginationParams = {
	count: 0,
	per: 10,
	page: 1,
	next_page: null,
	prev_page: null,
	from: null,
	to: null,
	num_pages: 1,
	term: '',
}

export default defaultPaginationParams
