import React from 'react'
import { Loading } from 'ui-common'

import './loading.sass'

export default function Firststep() {
	return (
		<div className='SendSMSFirstStepLoading'>
			<Loading variant='rect' width='100%' height='80%' className='SendSMSFirstStepLoading__IntroRect' />
			<Loading variant='rect' width='100%' height='80%' className='SendSMSFirstStepLoading__InstructionContainer' />
		</div>
	)
}
