// @flow

import axios from 'axios'

import { readCookies } from 'logic/auth'

import { refreshTokenMiddleware, checkForExpiredToken, confirmSessionStatus } from './middlewares'

const BASE_URL = (process.env.REACT_APP_API_URL || 'http://localhost:3000') + '/v1'

refreshTokenMiddleware(axios)
checkForExpiredToken(axios)
confirmSessionStatus(axios)

const request = async function(req) {
	try {
		const response = await axios(req)
		return response
	} catch (e) {
		if (e.response) {
			const {
				status,
				data: { error_message, ...rest },
			} = e.response
			const err = { status, errorMessage: error_message || 'Failed to perform request, please try again.', ...rest }
			throw err
		} else {
			const errObject = e.toJSON()
			const err = { status: errObject.code, errorMessage: errObject.message, isNetworkError: true }
			throw err
		}
	}
}

const DEFAULT_HEADERS = {
	'Content-type': 'application/json',
	'X-SMSLEOPARD-TOKEN': window.localStorage.getItem('x-smsleopard-token') || readCookies().auth_token || '',
	'X-SMSLEOPARD-ACCOUNT-ID': window.localStorage.getItem('x-smsleopard-account-id') || readCookies().account_id || '',
	'Accept-Language': window.localStorage.getItem('i18nextLng') || readCookies().preferred_language || '',
}

type RequestParameters = {
	params?: {},
	url: string,
	headers?: {},
	data?: {},
}

class Request {
	static get({ params = {}, url, headers = DEFAULT_HEADERS }: RequestParameters): Promise<any> {
		return request({
			method: 'GET',
			url: BASE_URL + url,
			params,
			headers,
		})
	}

	static post({ data = {}, params = {}, headers = DEFAULT_HEADERS, url }: RequestParameters): Promise<any> {
		return request({
			method: 'POST',
			url: BASE_URL + url,
			data,
			params,
			headers: Object.assign(DEFAULT_HEADERS, headers),
		})
	}

	static put({ data = {}, params = {}, url, headers = DEFAULT_HEADERS }: RequestParameters): Promise<any> {
		return request({
			method: 'PUT',
			url: BASE_URL + url,
			data,
			params,
			headers,
		})
	}

	static patch({ data = {}, params = {}, url, headers = DEFAULT_HEADERS }: RequestParameters): Promise<any> {
		return request({
			method: 'PATCH',
			url: BASE_URL + url,
			data,
			params,
			headers,
		})
	}

	static delete({ params = {}, data = {}, url, headers = DEFAULT_HEADERS }: RequestParameters): Promise<any> {
		return request({
			method: 'DELETE',
			url: BASE_URL + url,
			data,
			params,
			headers,
		})
	}

	static filedownload({ url, params = {}, headers = DEFAULT_HEADERS }: RequestParameters): Promise<any> {
		return request({
			url: BASE_URL + url,
			method: 'GET',
			responseType: 'blob',
			params,
			headers,
		})
	}

	static externalFileDownload({ url }: { url: string }): Promise<any> {
		return request({
			url: url,
			method: 'GET',
			responseType: 'blob',
		})
	}
}

export default Request
