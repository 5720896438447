import Request from '../request'

import type { PaginationParams } from 'types/api/requests/common'
import type { AxiosResponseType, CommonError } from 'types/api/responses/common'
import type {
	surveyDetailsForm,
	surveyListResponse,
	questionListResponse,
	QuestionForm,
	surveyResponseOptionForm,
	responseOptionList,
	responseListResponse,
	responseSummaryList,
	surveyDetailResponse,
	responseOptionResponse,
	questionResponse,
	fetchDraftIdReponseType,
	singleQuestion,
	PaginationResponse,
	singleResponseOption,
} from 'types/api/responses/surveys'
import type { SurveyOutboxPaginationParams, SurveyOutboxResponse } from 'types/api/responses/messages'
import type { SurveyDraftIdState } from '../../website/Surveys/SendSurvey/SendSurveyContext'

export function updateSurvey(form: surveyDetailsForm): Promise<surveyDetailResponse> {
	return Request.put({
		url: `/surveys/${form.id}`,
		data: form,
	})
}

export function saveSurvey(form: surveyDetailsForm): Promise<surveyDetailResponse> {
	return Request.post({
		url: `/surveys`,
		data: form,
	})
}

export function getSurveyDetails(id: number): Promise<surveyDetailResponse> {
	return Request.get({
		url: `/surveys/${id}`,
	})
}

export function deleteSurvey(id: number): Promise<surveyDetailResponse> {
	return Request.delete({
		url: `/surveys/${id}`,
	})
}

export function deactivateSurvey(id: number): Promise<surveyDetailResponse> {
	return Request.post({
		url: `/surveys/${id}/deactivate`,
	})
}

export function publishSurvey(surveyID: number): Promise<surveyDetailResponse> {
	return Request.post({
		url: `/surveys/${surveyID}/publish`,
	})
}

export function listAccountSurveys(accountID: number, params: PaginationParams): Promise<AxiosResponseType<surveyListResponse>> {
	return Request.get({
		url: `/accounts/${accountID}/surveys`,
		params,
	})
}

export function listSurveyQuestions(
	surveyID: number,
	params: PaginationParams,
): Promise<AxiosResponseType<questionListResponse>> {
	return Request.get({
		url: `/surveys/${surveyID}/survey_questions`,
		params,
	})
}

export function updateSurveyQuestion(form: QuestionForm): Promise<questionResponse> {
	return Request.put({
		url: `/survey_questions/${form.id}`,
		data: form,
	})
}

export function saveSurveyQuestion(form: QuestionForm): Promise<questionResponse> {
	return Request.post({
		url: `/survey_questions`,
		data: form,
	})
}

export function deleteQuestion(questionID: number): Promise<{ err?: CommonError }> {
	return Request.delete({
		url: `/survey_questions/${questionID}`,
	})
}

export function saveResponseOption(form: surveyResponseOptionForm): Promise<{ data: singleResponseOption }> {
	return Request.post({
		url: `/survey_question_response_options`,
		data: form,
	})
}

export function updateResponseOption(id: number, payload: { response: string; rank?: number }): Promise<responseOptionResponse> {
	return Request.put({
		url: `/survey_question_response_options/${id}`,
		data: payload,
	})
}

export function listResponseOptions(
	surveyQuestionID: number,
	params: {
		per: number
	},
): Promise<AxiosResponseType<responseOptionList>> {
	return Request.get({
		url: `/survey_questions/${surveyQuestionID}/survey_question_response_options`,
		params,
	})
}

export function deleteResponseOption(optionID: number): Promise<{ err?: CommonError }> {
	return Request.delete({
		url: `/survey_question_response_options/${optionID}`,
	})
}

export function responseSummariesForQuestion(questionID: number): Promise<AxiosResponseType<responseSummaryList>> {
	return Request.get({
		url: `/survey_questions/${questionID}/survey_question_response_summary`,
	})
}

export function listQuestionResponses(
	questionID: number,
	params: PaginationParams,
): Promise<AxiosResponseType<responseListResponse>> {
	return Request.get({
		url: `/survey_questions/${questionID}/survey_question_responses`,
		params,
	})
}

export function fetchSurveyOutboxMessages({
	params,
	accountId,
}: {
	params: SurveyOutboxPaginationParams
	accountId: number
}): Promise<AxiosResponseType<SurveyOutboxResponse>> {
	return Request.get({
		url: `/accounts/${accountId}/messages`,
		params,
	})
}

export const fetchdraftIdDetails = (draftId: SurveyDraftIdState): Promise<AxiosResponseType<fetchDraftIdReponseType>> => {
	return Request.get({
		url: `/drafts/${draftId.value}`,
	})
}

export const routeQuestionOrResponseOption = (payload: {
	next_question_id: number
	question_id?: number
	response_option_id?: number
}): Promise<any> => {
	return Request.post({
		url: '/survey_question_routes',
		data: payload,
	})
}

export type SurveyCompletionMessage = {
	id: number
	survey_id: number
	message: string
	created_at: string
	updated_at: string
}

export const fetchSurveyCompletionMessage = (surveyID: number): Promise<AxiosResponseType<SurveyCompletionMessage>> => {
	return Request.get({
		url: `/surveys/${surveyID}/survey_completion_message`,
	})
}

export const saveSurveyCompletionMessage = (payload: {
	survey_id: number
	message: string
}): Promise<AxiosResponseType<SurveyCompletionMessage>> => {
	return Request.post({
		url: '/survey_completion_messages',
		data: payload,
	})
}

export const updateSurveyCompletionMessage = (
	messageId: number,
	payload: {
		message: string
	},
): Promise<AxiosResponseType<SurveyCompletionMessage>> => {
	return Request.put({
		url: `/survey_completion_messages/${messageId}`,
		data: payload,
	})
}

export const sendSimulatorResponse = (payload: {
	question_id?: number
	response: string
	short_code_id: number
	phone_number: string
}): Promise<
	AxiosResponseType<{
		survey_completion_message: SurveyCompletionMessage | null
		survey_question: singleQuestion | null
	}>
> => {
	return Request.post({
		url: '/next_survey_question',
		data: payload,
	})
}

export const fetchResponseOptionQuestions = (
	responseOptionId: number,
): Promise<
	AxiosResponseType<{
		questions: singleQuestion[]
		pagination: PaginationResponse
	}>
> => {
	return Request.get({
		url: `/survey_question_response_options/${responseOptionId}/survey_questions`,
	})
}

type SurveyResponses = {
	phone_number: string
	responses: {
		question_id: number
		response: string
		created_at: string
	}[]
}

export const fetchSurveyPhoneResponses = (
	surveyId: number,
	order?: 'ASC' | 'DESC',
	page?: number,
): Promise<
	AxiosResponseType<{
		survey_responses: SurveyResponses[]
		pagination: PaginationParams
	}>
> => {
	return Request.get({
		url: `/surveys/${surveyId}/responses`,
		params: {
			order,
			page,
		},
	})
}
