import React, { Suspense } from 'react'
import 'wicg-inert'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import { isProductionApp } from './const'
import ErrorBoundaryComponent from './website/ErrorBoundary'
import { trackAppPerformance, setUpAnalytics } from 'utils/analytics'
import { setUpSentry } from './monitoring/sentry'

const axeConfig = {
	rules: [{ id: 'color-contrast', enabled: false }],
}

if (process.env.NODE_ENV !== 'production') {
	var axe = require('@axe-core/react')
	axe(React, ReactDOM, 1000, axeConfig)
}

if (isProductionApp) {
	setUpAnalytics()
	setUpSentry()
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<div>
		<Suspense fallback={<div />}>
			{/* @ts-ignore */}
			<ErrorBoundaryComponent>
				<App />
			</ErrorBoundaryComponent>
		</Suspense>
	</div>,
)

serviceWorkerRegistration.unregister()

reportWebVitals(isProductionApp ? trackAppPerformance : console.log)
