//@flow
import React from 'react'
import { ReactComponent as AfricanCustomizedBoy } from '../../../img/AfricanCustomizeBoy.svg'

export default function SurveySvgContainer(): React$Element<'div'> {
	return (
		<div div className='SurveySvgContainer'>
			<AfricanCustomizedBoy />
		</div>
	)
}
