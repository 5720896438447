// @flow
import { useEffect, useState } from 'react'

import parseToken from 'logic/parseToken'
import useOrganization from './useOrganization'

export type UserEnterpriseStatus = 'idle' | 'pending' | 'isOwner' | 'notOwner' | 'notEnterprise'

export default function useEnterpriseOwner(): UserEnterpriseStatus {
	const [enterpriseOwnerStatus, setEnterpriseOwnerStatus] = useState<UserEnterpriseStatus>('idle')
	const token = parseToken()
	const { data, error } = useOrganization()
	const isOrganizationEnterprise = organization => organization.type === 'enterprise'
	const isOrganizationIndividual = organization => organization.type === 'individual'
	const isUserOrganizationOwner = (organization, token) => organization.owner_id === token.user_id
	useEffect(() => {
		if (token) {
			if (!data && !error) return setEnterpriseOwnerStatus('pending')
			if (!data && error) return setEnterpriseOwnerStatus('notEnterprise')
			if (data) {
				if (isUserOrganizationOwner(data.data, token) && isOrganizationEnterprise(data.data)) {
					return setEnterpriseOwnerStatus('isOwner')
				}
				if (isUserOrganizationOwner(data.data, token) && isOrganizationIndividual(data.data)) {
					return setEnterpriseOwnerStatus('notOwner')
				}
				return setEnterpriseOwnerStatus('notEnterprise')
			}
		}
	}, [data, error, token])
	return enterpriseOwnerStatus
}
