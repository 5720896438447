// @flow
import useMediaQuery from '@mui/material/useMediaQuery'

type MediaQueryType = 'min' | 'max'

const useCustomMediaQuery = (width: number, queryType: MediaQueryType): boolean => {
	const query = queryType === 'min' ? `(min-width:${width}px)` : `(max-width:${width}px)`
	const queryResult = useMediaQuery(query)

	return queryResult
}

export default useCustomMediaQuery
