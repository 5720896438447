// @flow

export const pageTitles = {
	// auth pages
	register: 'Registration Page',
	login: 'Login Page',
	'forgot-password': 'Forgot Password Page',
	'reset-password': 'Reset Password Page',
	'account-verification': 'Account Verification Page',
	'email-verification-sent': 'Email Verification Sent Page',
	'accounts/register/confirm': 'Email Confirmation Page',
	// sendsms pages
	sendsms: 'SendSMS - Add Recipients Page',
	'sendsms/review-contacts': 'SendSMS - Review Contacts Page',
	'sendsms/compose-message': 'SendSMS - Compose Message Page',
	'sendsms/review-message': 'SendSMS - Review Message Page',
	'sendsms/successfully-sent': 'SendSMS - Successfully Sent Page',
	// inbox
	inbox: 'Inbox Page',
	// outbox pages
	outbox: 'Outbox Page',
	'outbox/scheduled': 'Scheduled Messages Page',
	'outbox/attachments': 'Attachments Page',
	'scheduled/scheduled_ID': 'Scheduled Message Details Page',
	'messages/messages_ID': 'Message Details Page',
	// contacts pages
	contacts: 'Contacts Page',
	'contacts/groups': 'Groups Page',
	'contacts/blocklist': 'Blocklist Page',
	'contacts/groups/groups_ID': 'Group Details Page',
	'contacts/groups/add': 'Create A Group Page',
	'contacts/upload': 'Upload Contacts Page',
	'contacts/add': 'Add Contact Page',
	'contacts/block': 'Block Contacts Page',
	// billing
	billing: 'Billing Page',
	'billing/transactions': 'Transactions Page',
	'billing/topup': 'Top Up Page',
	'billing/topup/creditcards': 'Top Up Via Credit Card Page',
	'billing/topup/mpesa': 'Top Up Via M-Pesa Page',
	'billing/topup/bank': 'Top Up Via Bank EFT Page',
	'billing/topup/equitel': 'Top Up Via Equitel Page',
	'billing/topup/eazzyapp': 'Top UP Via Eazzyapp Page',
	'billing/lowbalance': 'Low Balance Alert Page',

	analytics: 'Analytics Page',
	'api-keys': 'API Keys Page',
	// settings
	settings: 'Profile Settings Page',
	'settings/profile-settings': 'Profile Settings Page',
	'settings/change-password': 'Change Password Page',
	// enterprise
	enterprise: 'Enterprise Page',
	'enterprise/accounts': 'Enterprise Accounts Page',
	// enterprise accounts
	'enterprise/accounts/accounts_ID': 'Enterprise Account Details Page',
	'enterprise/accounts/accounts_ID/messages': 'Entrprise Account Messages Page',
	'enterprise/accounts/accounts_ID/users': 'Entrprise Account Users Page',
	'enterprise/accounts/accounts_ID/transactions': 'Entrprise Account Transactions Page',
	'enterprise/accounts/accounts_ID/sender_ids': 'Enterprise Account Sender IDs Page',
	// enterprise users
	'enterprise/users': 'Enterprise Users Page',
	'enterprise/users/users_ID': 'Enterprise User Details Page',

	'enterprise/messages': 'Enterprise Messages Page',
	'enterprise/summary': 'Enterprise Summary Page',
	'enterprise/settings': 'Enterprise Settings Page',
	'files/files_ID': 'Attachment File Page',

	//send bulk money
	'send-bulk-money': 'Send Bulk Money Page',
}

export default function generatePageTitle(refinedPagePath: string): string {
	const pathWithoutFirstSlash = refinedPagePath.substring(1)
	const pageTitle = pageTitles[pathWithoutFirstSlash] ? ' | ' + pageTitles[pathWithoutFirstSlash] : ''
	return 'SMSLeopard Dashboard' + pageTitle
}
