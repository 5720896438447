// @flow

import type { Country } from 'types/api/responses/countries'

export const uniqueCurrencies = (countries: Country[]): Array<string> => {
	let currencies = new Set()
	for (let i = 0; i < countries.length; i++) {
		if (countries[i].currency !== '') {
			currencies.add(countries[i].currency)
		}
	}
	return Array.from(currencies)
}
