import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'

import { MessageProvider, withMessage } from 'ui-common'
import DefaultErrorComponent from 'ui-common/ErrorBoundary/defaultErrorComponent'
// import Header from './website/Header'
// import WelcomeToTheNewApp from './website/WelcomeToTheNewApp'
import Site from './website'
// import Footer from './website/Footer'
import DashboardLayout from './website/Layout/DashboardLayout'
// hooks
import useSystemState from './hooks/data/useSystemsState'
import { useProfileState } from 'website/Settings/useProfileData'

import './i18n'

import './const.sass'
import './layout.sass'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import AppProvider from 'website/AppProvider'

function App(props) {
	const { t } = useTranslation(['maintenance'])
	const systemState = useSystemState()
	const { profileState } = useProfileState()
	useEffect(() => {
		if (profileState.status === 'resolved' && window.ChatSasa) {
			const chatData = {
				user_id: profileState?.data.id,
				user_name: `${profileState?.data.first_name} ${profileState?.data.last_name}`,
				email: profileState?.data.email,
			}

			window.ChatSasa.setUserData(chatData)
		}
	}, [profileState])
	if (systemState === 'active')
		return (
			<Router>
				{/* <div className='App'>
					{<Header {...props} />}
					<main className='App__content'>
						<Site {...props} />
					</main>
					<WelcomeToTheNewApp />
					<footer className='Footer'>
						<Footer />
					</footer>
				</div> */}

				<AppProvider>
					<DashboardLayout>
						{/* <Header {...props} /> */}
						<Site {...props} />
					</DashboardLayout>
				</AppProvider>
			</Router>
		)
	return (
		<div className='MaintainanceMode__Container' data-testid='maintainance-mode-container'>
			<DefaultErrorComponent>
				<p>{t('message')}</p>
			</DefaultErrorComponent>
		</div>
	)
}

const AppWithMessage = withMessage(App)

export default function AppContainer() {
	return (
		<Suspense fallback={<div />}>
			<MessageProvider>
				<AppWithMessage />
			</MessageProvider>
		</Suspense>
	)
}
