// @flow
import React from 'react'
import ButtonLink, { type ButtonLinkProps } from '../ButtonLink'

type TemplateDownloaderProps = {
	text: string,
	url: string,
}
export default function TemplateDownloader({
	text,
	url,
}: TemplateDownloaderProps): React$Element<(_0: ButtonLinkProps) => React$Element<'button'>> {
	return (
		<ButtonLink iconName='download'>
			<a href={url} target='_blank' rel='noreferrer'>
				{text}
			</a>
		</ButtonLink>
	)
}
