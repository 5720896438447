// @flow

import Request from '../request'
import type { AxiosResponseType } from 'types/api/responses/common'
import type { BannerAPIResponse } from 'types/api/responses/banner'

export function fetchBanner(): Promise<AxiosResponseType<BannerAPIResponse>> {
	return Request.get({
		url: '/banners?term=visible&page=1&per=1',
	})
}
