// @flow
import React from 'react'
import { BoldText } from 'ui-common'
import { useTranslation } from 'react-i18next'

import './style.sass'
import type { BoldTextProps } from 'ui-common/BoldText'

export default function FileUploadSizeDislaimer(): React$Element<(_0: BoldTextProps) => React$Element<'b'>> {
	const { t } = useTranslation(['common'])
	return <BoldText className='FileDisclaimer'>{t('fileSizeDisclaimer')}</BoldText>
}
