// @flow

// lowBalanceResponse
import Request from '../request'

import type { AxiosResponseType } from 'types/api/responses/common'
import type {
	lowBalanceResponse,
	GetLowBalanceResponse,
	CreateLowBalanceNotification,
	UserLowBalanceAlertNotificationsResponse,
} from 'types/api/responses/billing/low_balance'
import type { AccountBalance } from 'types/api/responses/billing'
import type { TransactionsResponse, PaypalTransactionResponse } from 'types/api/responses/billing/transactions'

export function updateBalance({
	lowBalanceNotificationID,
	data,
}: {
	lowBalanceNotificationID: number,
	data: { amount: number, send_via_email: boolean, send_via_sms: boolean, additional_emails: string[], currency: string },
}): Promise<AxiosResponseType<lowBalanceResponse>> {
	return Request.put({
		url: `/account_low_balance_notifications/${lowBalanceNotificationID}`,
		data,
	})
}

export function createLowBalanceNotification({ data }: { data: CreateLowBalanceNotification }): Promise<AxiosResponseType<any>> {
	return Request.post({
		url: `/account_low_balance_notifications`,
		data,
	})
}

export function getSingleLowBalance({ id }: { id: number }): Promise<AxiosResponseType<lowBalanceResponse>> {
	return Request.get({
		url: `/low_balance_notifications/${id}`,
	})
}

export function deleteSingleLowBalance({ id }: { id: number }): Promise<AxiosResponseType<any>> {
	return Request.delete({
		url: `/low_balance_notifications/${id}`,
	})
}

export function getBalance({
	accountId,
	userId,
}: {
	accountId: number,
	userId: number,
}): Promise<AxiosResponseType<GetLowBalanceResponse>> {
	return Request.get({
		url: `/accounts/${accountId}/users/${userId}/low_balance_notifications`,
	})
}

export function mpesaTopUp({
	data,
}: {
	data: { amount: number, phone: string, account_id: number },
}): Promise<AxiosResponseType<{ success: boolean }>> {
	return Request.post({
		url: `/payment_requests`,
		data,
	})
}
export function AirtelTopUp({
	data,
}: {
	data: { amount: number, phone: string, account_id: number },
}): Promise<AxiosResponseType<{ success: boolean }>> {
	return Request.post({
		url: `/airtel_payment_requests`,
		data,
	})
}

export function getBalanceInAccount({ accountId }: { accountId: number }): Promise<AxiosResponseType<AccountBalance>> {
	return Request.get({
		url: `/accounts/${accountId}/balance`,
	})
}

export function fetchAccountTransactions({
	accountId,
	params,
}: {
	accountId: number,
	params: Object,
}): Promise<AxiosResponseType<TransactionsResponse>> {
	return Request.get({
		url: `/accounts/${accountId}/transactions`,
		params,
	})
}

export function getPaypalTransaction({ id }: { id: number }): Promise<AxiosResponseType<PaypalTransactionResponse>> {
	return Request.get({
		url: `/paypal_confirmations/${id}`,
	})
}

export function confirmPaypalTransaction({ id }: { id: number }): Promise<AxiosResponseType<PaypalTransactionResponse>> {
	return Request.post({
		url: `/paypal_confirmations/${id}/confirm`,
	})
}

export function getUserLowBalanceAlertNotifications({
	user_id,
	account_id,
}: {
	user_id: number,
	account_id: number,
}): Promise<AxiosResponseType<UserLowBalanceAlertNotificationsResponse>> {
	return Request.get({
		url: `/users/${user_id}/accounts/${account_id}/low_balance_notifications`,
	})
}

export function validateEmailAddresses({ emails }: { emails: string[] }): Promise<any> {
	return Request.post({
		url: '/emails/validate',
		data: {
			emails,
		},
	})
}
