// @flow

import React from 'react'
import { Loading } from 'ui-common'

import './style.sass'

export default function AnalyticsLoading(): React$Element<'div'> {
	return (
		<div className='Analytics__Loading__Container'>
			<div className='Analytics__Loading__header'>
				<Loading width='50%' height='20px' />
			</div>
			<Loading width='100%' height='400px' />
			<div className='Analytics__Loading__header'>
				<Loading width='50%' height='20px' />
			</div>
			<div className='Analytics__Telco__Loading__Container'>
				{new Array(4).fill(0).map((_, key) => (
					<div key={key} className='Telco'>
						<Loading height='100%' />
					</div>
				))}
			</div>
		</div>
	)
}
