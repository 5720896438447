import React from 'react'
import cs from 'classnames'
import DateTimePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './style.sass'

export const DatePicker = ({
	className = '',
	onClearDate = () => {},
	standalone,
	value,
	inputProps = {
		placeholder: '📅  21/12/2020',
		className: '',
	},
	...props
}) => {
	const cx = cs('DatePickerContainer--dateValueContainer', inputProps.className)
	return (
		<DateTimePicker
			isClearable
			{...props}
			selected={value}
			autoComplete={'off'}
			showTimeSelect
			timeFormat='HH:mm'
			timeIntervals={5}
			timeCaption='time'
			placeholderText={inputProps.placeholder}
			dateFormat='MMMM dd, yyyy HH:mm'
			className={cx}
		/>
	)
}
