import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { NavLink } from 'react-router-dom'
import type { HeaderLink } from './DashboardLayout'
import cn from 'classnames'

export default function MenuList({ headerLink }: { headerLink: HeaderLink }) {
	return (
		<Menu as='div' className='relative inline-block text-left'>
			<div>
				<MenuButton
					as={NavLink}
					className='inline-flex gap-[0.8rem] w-full justify-center rounded-md p-[0.8rem] items-center text-[1.4rem] leading-[2.4rem] font-medium text-gray-700 hover:text-primary hover:bg-gray-50'
					to='/sendsms/outbox'
					activeClassName='bg-gray-50 text-primary'>
					<headerLink.icon
						className={cn('group-hover:text-primary', 'h-[2rem] w-[2rem] shrink-0')}
						aria-hidden='true'
					/>
					{headerLink.name}
					<ChevronDownIcon className='-mr-[0.4rem] h-[2rem] w-[2rem]' aria-hidden='true' />
				</MenuButton>
			</div>
			<Transition
				as={Fragment}
				enter='transition ease-out duration-100'
				enterFrom='transform opacity-0 scale-95'
				enterTo='transform opacity-100 scale-100'
				leave='transition ease-in duration-75'
				leaveFrom='transform opacity-100 scale-100'
				leaveTo='transform opacity-0 scale-95'>
				<MenuItems className='absolute right-0 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
					{headerLink.menuLinks?.map(menuLink => (
						<MenuItem>
							{({ active }) => (
								<NavLink
									to={menuLink.to}
									activeClassName='bg-primary text-white'
									className={activeLink =>
										cn(
											'text-gray-700 items-center',
											'group flex gap-x-[0.8rem] p-[0.8rem] text-[1.4rem] leading-[2.4rem] font-medium',
											{
												'bg-gray-100 text-primary': active && !activeLink,
												'hover:text-primary hover:bg-gray-50': !activeLink,
											},
										)
									}>
									{menuLink.name} {menuLink.count ? `(${menuLink.count})` : ''}
								</NavLink>
							)}
						</MenuItem>
					))}
				</MenuItems>
			</Transition>
		</Menu>
	)
}
