// @flow
import React from 'react'
import { Loading } from 'ui-common'

import './loading.sass'

export default function LoadingSendScreen(): React$Element<'div'> {
	return (
		<div className='SurveyPageLoading'>
			<Loading variant='rect' width='20%' height='5%' className='' />
			<div className='SurveyNavLoading'>
				<Loading variant='rect' width='20%' height='100%' className='SurveyNavLoadingTab' />
				<Loading variant='rect' width='20%' height='100%' className='SurveyNavLoadingTab' />
				<Loading variant='rect' width='20%' height='100%' className='SurveyNavLoadingTab' />
			</div>
			<div className='SurveyContainerLoading'>
				<Loading variant='rect' width='75%' height='55%' />
			</div>
		</div>
	)
}
