// @flow
import type { Location } from 'react-router-dom'
import { numeric } from 'logic/regex'
import parseToken from 'logic/parseToken'
import { GOOGLE_ANALYTICS_TRACKING_ID } from '../../const'

export const trackPage = ({ page, path, title }: { page: string, path: string, title: string }) => {
	if (typeof window.gtag === 'function') {
		window.gtag('event', 'page_view', {
			page_location: page,
			page_path: path,
			page_title: title,
		})
	}
}

export const trackEvent = ({
	eventCategory,
	eventAction,
	eventLabel,
}: {
	eventCategory: string,
	eventAction: string,
	eventLabel: string,
}) => {
	if (typeof window.gtag === 'function') {
		window.gtag('event', eventAction, {
			event_label: eventLabel,
			event_category: eventCategory,
		})
	}
}

export const trackAppPerformance = ({ name, delta, id }: { name: string, delta: number, id: string }) => {
	if (typeof window.gtag === 'function') {
		window.gtag('event', name, {
			event_category: 'Web Vitals',
			value: Math.round(name === 'CLS' ? delta * 1000 : delta),
			event_label: id,
			non_interaction: true,
		})
	}
}

export const trackExternalLinkEvent = ({ link, eventCategory }: { link: string, eventCategory: string }) => {
	if (typeof window.gtag === 'function') {
		window.gtag('event', 'click', {
			event_category: eventCategory,
			event_label: link,
			transport_type: 'beacon',
		})
	}
}

export const setAnalyticsUserProperties = ({ token }: { token: string }) => {
	const parsedToken = parseToken(token)
	if (typeof window.gtag === 'function' && parsedToken) {
		window.gtag('set', 'user_properties', {
			user_id: parsedToken.user_id,
		})
	}
}

export const removeUniqueIdFromPath = (location: Location): string => {
	const splitPath = location.pathname.split('/')
	let resultString = [splitPath[0]]
	for (let idx = 1; idx < splitPath.length; idx++) {
		if (isValidNumber(splitPath[idx])) {
			const previousString = splitPath[idx - 1]
			const placeholderForId = isValidNumber(previousString) ? 'ID' : previousString + '_ID'
			resultString.push(placeholderForId)
		} else {
			resultString.push(splitPath[idx])
		}
	}
	return resultString.join('/')
}

function isValidNumber(str: string): boolean {
	return numeric.test(str)
}

export function setUpAnalytics() {
	window.dataLayer = window.dataLayer || []
	function gtag(...args: Array<any>) {
		if (Array.isArray(window.dataLayer)) window.dataLayer.push(arguments)
	}
	gtag('js', new Date())
	gtag('config', GOOGLE_ANALYTICS_TRACKING_ID, {
		send_page_view: false,
	})
}
