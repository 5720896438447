import React from 'react'
import { Loading } from 'ui-common'

import Table from './table'

import './style.sass'

export default function FullpagetableWithHeader({ number = 20 }) {
	return (
		<div className='FullpagetableContainer'>
			<div className='FullpagetableContainer__Header'>
				<Loading variant='rect' className='FullpagetableContainer__Header__Item' />
				<Loading variant='rect' className='FullpagetableContainer__Header__Item' />
				<Loading variant='rect' className='FullpagetableContainer__Header__Item' />
			</div>
			<div className='FullpagetableContainer__Body'>
				<Table number={number} />
			</div>
		</div>
	)
}
