import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

type CustomInfiniteScrollProps = {
	dataLength: number,
	height: number,
	fetchMoreData: () => void,
	hasMore: boolean | null | number,
	children: React.ReactNode,
	message: string,
}

const CustomInfiniteScroll = ({ dataLength, height, fetchMoreData, hasMore, children, message }: CustomInfiniteScrollProps) => {
	return (
		<InfiniteScroll
			dataLength={dataLength}
			height={height}
			next={fetchMoreData}
			hasMore={hasMore}
			loader={<div>Loading...</div>}
			endMessage={
				<p style={{ textAlign: 'center' }}>
					<b>{message}</b>
				</p>
			}>
			{children}
		</InfiniteScroll>
	)
}

export default CustomInfiniteScroll
