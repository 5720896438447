// @flow
import React, { type Node } from 'react'
import { withTranslation } from 'react-i18next'

import Button from 'ui-common/Button'

import DefaultErrorComponent from 'ui-common/ErrorBoundary/defaultErrorComponent'

type Props = {
	children: Node,
	t: string => any,
}

type State = {
	hasError: boolean,
}

interface ErrorInfo {
	componentStack: string;
}

const CHUNKERROR_PAGE_RELOAD_KEY = 'did-chunk-error-make-page-reload'
const TIME_TO_RESET_PAGE_RELOAD = 5000
class ErrorBoundaryComponent extends React.Component<Props, State> {
	resetPageReloadTimeout: TimeoutID
	constructor(props: Props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(): { hasError: boolean, ... } {
		return { hasError: true }
	}

	componentDidMount() {
		this.resetPageReloadTimeout = setTimeout(() => this.resetStoredPageReloadError(), TIME_TO_RESET_PAGE_RELOAD)
	}

	componentWillUnmount() {
		clearTimeout(this.resetPageReloadTimeout)
	}

	didPageAlreadyReload(): boolean {
		const item = localStorage.getItem(CHUNKERROR_PAGE_RELOAD_KEY)
		return item === 'yes'
	}

	saveChunkErrorReloadAction() {
		localStorage.setItem(CHUNKERROR_PAGE_RELOAD_KEY, 'yes')
	}

	resetStoredPageReloadError() {
		localStorage.removeItem(CHUNKERROR_PAGE_RELOAD_KEY)
	}

	areTargetkeyWordsInErrorPresent(error: Error): boolean {
		return error.name.indexOf('ChunkLoadError') > -1 || error.name.indexOf('Loading CSS chunk') > -1
	}

	isThisAValidChunkLoadError(error: Error): boolean {
		return this.areTargetkeyWordsInErrorPresent(error) && !this.didPageAlreadyReload()
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		if (this.isThisAValidChunkLoadError(error)) {
			this.saveChunkErrorReloadAction()
			window.location.reload(true)
			return
		}
	}

	localizedTranslationDidNotLoad(key: string, value: any) {
		return key === value
	}

	onErrorInstructions(): string[] {
		const instructionsKey = 'state.successfullyReported.instructions'
		const localizedInstructions = this.props.t(instructionsKey)
		if (this.localizedTranslationDidNotLoad(instructionsKey, localizedInstructions)) {
			return [
				'Something went wrong in the app and we have notified the team.',
				'We apologize for any inconvenience caused.',
				'In the meantime, kindly click the button below to reload the page.',
			]
		}
		return localizedInstructions
	}

	onErrorActionToTake(): string {
		const actionToTakeKey = 'state.successfullyReported.actions.reloadPage'
		const localizedAction: string = this.props.t(actionToTakeKey)
		if (this.localizedTranslationDidNotLoad(actionToTakeKey, localizedAction)) {
			return 'Reload the page'
		}
		return localizedAction
	}

	render(): React$Element<(_0: {}) => Node> | React$Element<(props: Props) => React$Element<'div'>> | Node {
		const { hasError } = this.state
		if (hasError) {
			return (
				<DefaultErrorComponent>
					{this.onErrorInstructions().map(item => (
						<p key={item}>{item}</p>
					))}
					<br />
					<Button onClick={() => window.location.reload()}>{this.onErrorActionToTake()}</Button>
				</DefaultErrorComponent>
			)
		}

		return this.props.children
	}
}

export default (withTranslation('ui-common/error-boundary')(ErrorBoundaryComponent): React.Component<Props, State>)
