// @flow

import Request from '../request'
import type { AxiosResponseType } from '../../types/api/responses/common'
import type { CreateAPIKey } from '../../types/api/requests/apiKeys'

import type { CreatedAPIKeyResponse, ApiKeyItem, ListingAPIKeysReponse } from '../../types/api/responses/apiKeys'

export function createApiKey({ data }: { data: CreateAPIKey }): Promise<AxiosResponseType<CreatedAPIKeyResponse>> {
	return Request.post({
		url: `/api_keys`,
		data,
	})
}

export function listAPIKeys({ accountId }: { accountId: number }): Promise<AxiosResponseType<ListingAPIKeysReponse>> {
	return Request.get({
		url: `/accounts/${accountId}/api_keys`,
	})
}

export function deleteAPIKey({ apiKeyID }: { apiKeyID: number }): Promise<AxiosResponseType<ApiKeyItem>> {
	return Request.delete({
		url: `/api_keys/${apiKeyID}`,
	})
}
