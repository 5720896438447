// @flow

export type token = {
	exp: number,
	is_admin: boolean,
	refresh: number,
	session_id: number,
	status: string,
	user_id: number,
}

export default function parseToken(token: ?string): token | null {
	try {
		const tokenStr = token ? token : localStorage.getItem('x-smsleopard-token')
		if (tokenStr) return JSON.parse(atob(tokenStr.split('.')[1]))
		return null
	} catch (e) {
		return null
	}
}
