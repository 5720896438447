// @flow

import { useEffect, useState } from 'react'

import parseToken from 'logic/parseToken'
import useOrganization from './useOrganization'

export type UserOrganizationStatus = 'notOwner' | 'owner'

export default function useOrganizationOwner(): UserOrganizationStatus {
	const [ownerStatus, setOwnerStatus] = useState<UserOrganizationStatus>('notOwner')
	const token = parseToken()
	const { data } = useOrganization()

	useEffect(() => {
		if (token && data) {
			if (data.data.owner_id === token.user_id) return setOwnerStatus('owner')
		}
	}, [data, token])
	return ownerStatus
}
