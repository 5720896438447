import React from 'react'
import Loader from 'react-loader-spinner'
import cs from 'classnames'
import './style.sass'

// Using the https://www.npmjs.com/package/react-loader-spinner

export default function Spinner({ color = '#3B57F7', height = 36, width = 36, type = 'Oval', text, className, align = 'row' }) {
	const cx = cs('LoadingSpinnerContainer', className, {
		'LoadingSpinnerContainer--row': align === 'row',
		'LoadingSpinnerContainer--column': align === 'column',
	})
	return (
		<div className={cx}>
			<Loader type={type} color={color} height={height} width={width} />
			<p className='LoadingSpinnerContainer__text'>{text}</p>
		</div>
	)
}
