import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import useQuestions from '../../useQuestions'
import { useImmer } from 'use-immer'
import { getSurveyDetails, listSurveyQuestions } from 'api/surveys'
import type { singleQuestion, singleSurvey, surveyQuestionListStates } from 'types/api/responses/surveys'
import { useParams } from 'react-router-dom'
import type { PaginationParams } from 'types/api/requests/common'
import defaultPaginationParams from 'data-provider'
import SurveySimulator from 'website/Surveys/SurveySimulator/SurveySimulator'
import SurveyNav from '../SurveyNav'
import SurveySvgContainer from '../SurveySvgContainer'

type SurveyContextEntity = {
	questions: singleQuestion[]
	setQuestions: (f: (draft: singleQuestion[]) => void | singleQuestion[]) => void
	status: surveyQuestionListStates
	setStatus: (f: (draft: surveyQuestionListStates) => void | surveyQuestionListStates) => void
	refreshQuestions: () => void
	setErrorMessage: (message: string) => void
	setSuccessMessage: (message: string) => void
	surveyStatus: string
	surveySenderId?: {
		id: number
		name: string
	} | null
	surveyDetails: singleSurvey | null
	isLoadingSurveyQuestions: boolean
}

type SurveyProviderProps = {
	children: ReactNode
	setErrorMessage: (message: string) => void
	setSuccessMessage: (message: string) => void
}

const SurveyContext = createContext<SurveyContextEntity>({
	questions: [],
	setQuestions: () => null,
	status: 'idle',
	setStatus: () => {},
	refreshQuestions: () => {},
	setErrorMessage: () => null,
	setSuccessMessage: () => null,
	surveyStatus: '',
	surveySenderId: null,
	surveyDetails: null,
	isLoadingSurveyQuestions: false,
})

export const useSurveyContext = () => useContext(SurveyContext)

const startParams: PaginationParams = JSON.parse(JSON.stringify(defaultPaginationParams))
startParams.per = 80
startParams.type = 'parent'

const SurveyProvider = ({ children, setSuccessMessage, setErrorMessage }: SurveyProviderProps) => {
	const [questions, setQuestions] = useImmer<singleQuestion[]>([])
	const [status, setStatus] = useImmer<surveyQuestionListStates>('idle')

	const { id } = useParams<{ id?: string }>()

	const surveyID = id ? parseInt(id) : null

	const [surveyStatus, setSurveyStatus] = useState<string>('idle')
	const [surveyDetails, setSurveyDetails] = useState<singleSurvey | null>(null)

	useEffect(() => {
		if (surveyID) {
			getSurveyDetails(surveyID).then(survey => {
				setSurveyStatus(survey.data.status)
				setSurveyDetails(survey.data)
			})
		}
	}, [surveyID])

	const { data, error, mutate, isLoading: isLoadingSurveyQuestions } = useQuestions({ params: startParams, surveyID })

	useEffect(() => {
		if (questions.length === 0 && !isLoadingSurveyQuestions) {
			setQuestions(() => [...questions, null])
		}
	}, [isLoadingSurveyQuestions, questions, setQuestions])

	useEffect(() => {
		if (error) {
			setErrorMessage(error.errorMessage)
			if (error.isNetworkError) {
				return setStatus(() => 'network_error')
			}
			return setStatus(() => 'rejected')
		}

		if (data && JSON.stringify(data.data.questions) !== JSON.stringify(questions)) {
			setQuestions(() => data.data.questions)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, error, setErrorMessage, setQuestions, setStatus, surveyID])

	const refreshQuestions = () => {
		if (questions.length === 1 && surveyID) {
			const questions = async () => {
				await listSurveyQuestions(surveyID, startParams).then(data => {
					setQuestions(() => data.data.questions)
				})
			}
			questions()
		}
		mutate()
	}

	const params = useParams()
	const view = params['0'] || 'details'

	const excludeSimulator =
		['send', 'responses', 'outbox', 'messages'].includes(view) ||
		(view === 'questions' && surveyDetails?.status === 'published')

	return (
		<SurveyContext.Provider
			value={{
				questions,
				setQuestions,
				status,
				setStatus,
				refreshQuestions,
				setErrorMessage,
				setSuccessMessage,
				surveyStatus,
				surveySenderId: {
					id: surveyDetails?.short_code_id || 0,
					name: surveyDetails?.short_code || '',
				},
				surveyDetails,
				isLoadingSurveyQuestions,
			}}>
			<div className='mx-20 flex flex-col gap-8'>
				{surveyID && <SurveyNav surveyID={surveyID} setErrorMessage={setErrorMessage} />}
				<div className='flex gap-10'>
					<div className={!excludeSimulator ? 'w-2/3' : 'w-full'}>{children}</div>
					{!excludeSimulator &&
						(surveyDetails ? (
							<div className='sticky top-[8.5rem] w-1/3 h-max'>
								<SurveySimulator />
							</div>
						) : (
							<SurveySvgContainer />
						))}
				</div>
			</div>
		</SurveyContext.Provider>
	)
}

export default SurveyProvider
