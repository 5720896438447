import React, { Fragment, useEffect, useState } from 'react'
import { Menu, MenuButton, MenuItems, MenuItem, Transition } from '@headlessui/react'
import {
	ChevronDownIcon,
	CheckCircleIcon,
	Bars3Icon,
	Cog6ToothIcon,
	ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/20/solid'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useProfileData from '../Settings/useProfileData'
import { Avatar, Loading } from 'ui-common'
// @ts-ignore
import defaultImage from '../../img/default-logo.png'
import useAccountBalance from 'hooks/data/useAccountBalance'
import useUserAccounts from 'hooks/data/useUserAccounts'
import type { GenericDetailsStateMachine } from 'types/api/common'
import type { AccountBalance } from 'types/api/responses/billing'
import type { credentials as Credentials } from 'types/auth'
import formatMoney from 'logic/formatMoney'
import cn from 'classnames'
import userAuthenticationState, { removeAuthCookies } from 'logic/auth'
import { logoutUser } from 'api/auth'
import type { Navigation } from './DashboardLayout'
import type { Account } from 'types/api/responses/accounts'
import MenuList from './MenuList'

type BalanceStatus = GenericDetailsStateMachine<AccountBalance>

const TopHeader = ({ setSidebarOpen, navigation }: { setSidebarOpen: any; navigation: Navigation[] }): any => {
	const { userProfileData } = useProfileData()
	const { t } = useTranslation(['header'])
	const authState = userAuthenticationState()
	const [, setLoadingLogout] = useState(false)
	const { push } = useHistory()
	const { pathname } = useLocation()

	function removeItemsFromLocalStorage() {
		removeAuthCookies()
		return ['x-smsleopard-token', 'x-smsleopard-account-id', 'x-smsleopard-organization-id', 'accounts'].map(item =>
			localStorage.removeItem(item),
		)
	}

	const handleLogout = () => {
		setLoadingLogout(true)
		return logoutUser()
			.then(() => {
				setLoadingLogout(false)
				// props.setSuccessMessage(t('successMessages.loggedOut'))
				removeItemsFromLocalStorage()
				push('/login')
				window.location.reload()
			})
			.then(() => {
				if ((window as any)?.ChatSasa) {
					;(window as any).ChatSasa.clearUserData()
				}
			})
			.catch(() => {
				removeItemsFromLocalStorage()
				setLoadingLogout(false)
				push('/login')
				window.location.reload()
			})
	}

	const userNavigation: { name: string; icon: any; to?: string; onClick?: () => any }[] = [
		{ name: 'Settings', icon: Cog6ToothIcon, to: '/settings' },
		{ name: 'Sign out', icon: ArrowLeftStartOnRectangleIcon, onClick: handleLogout },
	]

	const { data: accountsData } = useUserAccounts()

	if (authState.state === 'unauthenticated') return null

	const handleClickAccountItem = (account: Account) => () => {
		window.localStorage.setItem('x-smsleopard-account-id', account.id.toString())
		window.localStorage.setItem('x-smsleopard-organization-id', account.organization_id.toString())
		window.location.reload()
	}

	return (
		<div className='sticky top-0 z-40 flex h-[6.4rem] shrink-0 items-center gap-x-[1.6rem] border-b border-gray-200 bg-white px-[1.6rem] shadow-sm sm:gap-x-[2.4rem] sm:px-[2.4rem] lg:px-[3.2rem]'>
			<button type='button' className='-m-[1.6rem] p-[1.6rem] text-gray-700 lg:hidden' onClick={() => setSidebarOpen(true)}>
				<span className='sr-only'>Open sidebar</span>
				<Bars3Icon className='h-[2.4rem] w-[2.4rem]' aria-hidden='true' />
			</button>

			{/* Separator */}
			<div className='h-[2.4rem] w-px bg-gray-200 lg:hidden' aria-hidden='true' />

			<div className='flex flex-1 gap-x-[1.6rem] self-stretch lg:gap-x-[2.4rem]'>
				<div className='relative flex flex-1 '>
					<div className='md:flex gap-2 items-center hidden'>
						{navigation
							.find(nav => pathname.includes(nav.to))
							?.headerLinks?.map(headerLink => {
								if (headerLink.isHidden) return null
								if (headerLink.menuLinks) return <MenuList key={headerLink.to} headerLink={headerLink} />
								return (
									<NavLink
										key={headerLink.to}
										to={headerLink.isExternal ? { pathname: headerLink.to } : headerLink.to}
										target={headerLink.isExternal ? '_blank' : undefined}
										activeClassName='bg-gray-50 text-primary'
										className={cn(
											'border border-transparent text-gray-700 hover:text-primary hover:bg-gray-50 items-center',
											'group flex gap-x-[0.8rem] rounded-[0.6rem] p-[0.8rem] text-[1.4rem] leading-[2.4rem] font-medium',
											{
												'bg-primary text-white hover:bg-primary-100 hover:text-primary hover:border-primary mx-[0.8rem] py-[0.4rem]':
													headerLink.isHighlighted,
											},
										)}
										exact>
										<headerLink.icon
											className={cn('group-hover:text-primary', 'h-[2rem] w-[2rem] shrink-0')}
											aria-hidden='true'
										/>
										{headerLink.name} {headerLink.count ? `(${headerLink.count})` : ''}
									</NavLink>
								)
							})}
					</div>
				</div>

				<div className='flex items-center gap-x-[1.6rem] lg:gap-x-[2.4rem]'>
					<div className='flex gap-6 items-center'>
						<NavLink exact className='Nav__link' activeClassName='Nav__link--active' to='/billing/topup'>
							{t('authenticatedLinks.topUp')}
						</NavLink>
						<CurrentBalance credentials={authState.credentials} />
					</div>

					{/* Separator */}
					<div className='hidden lg:block lg:h-[2.4rem] lg:w-px lg:bg-gray-200' aria-hidden='true' />

					{/* Profile dropdown */}
					<Menu as='div' className='relative'>
						{userProfileData && (
							<MenuButton className='-m-[0.6rem] flex items-center p-[0.6rem]'>
								<span className='sr-only'>Open user menu</span>
								<Avatar
									size={5}
									imageAlt={userProfileData.data.username}
									key={userProfileData.data.avatar_url || defaultImage}
									url={userProfileData.data.avatar_url || defaultImage}
									defaultUrl={defaultImage}
								/>
								<span className='hidden lg:flex lg:items-center'>
									<span
										className='ml-[1.6rem] text-[1.4rem] font-semibold leading-[2.4rem] text-gray-900'
										aria-hidden='true'>
										{userProfileData.data.first_name + ' ' + userProfileData.data.last_name}
									</span>
									<ChevronDownIcon className='ml-[0.8rem] h-[2rem] w-[2rem] text-gray-400' aria-hidden='true' />
								</span>
							</MenuButton>
						)}

						<Transition
							as={Fragment}
							enter='transition ease-out duration-100'
							enterFrom='transform opacity-0 scale-95'
							enterTo='transform opacity-100 scale-100'
							leave='transition ease-in duration-75'
							leaveFrom='transform opacity-100 scale-100'
							leaveTo='transform opacity-0 scale-95'>
							<MenuItems className='absolute right-0 z-10 mt-[1rem] min-w-96 origin-top-right rounded-[0.6rem] bg-white py-[0.8rem] shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
								{accountsData && accountsData.data.accounts.length > 1 && (
									<>
										<div className='flex justify-between items-center px-14 py-2 font-semibold'>
											<span>Accounts</span>
											<MenuItem>
												{({ focus }) => (
													<NavLink
														to='/business-account'
														className={cn('text-lg px-4 py-1 rounded', {
															'bg-gray-100 underline': focus,
														})}>
														Manage
													</NavLink>
												)}
											</MenuItem>
										</div>
										{accountsData.data.accounts.map(account => {
											const isActive = authState.credentials.accountId.value === account.id
											return (
												<Fragment key={account.id}>
													<MenuItem>
														{({ focus }) => (
															<div
																onClick={handleClickAccountItem(account)}
																className={cn(
																	'px-14 py-6 flex items-center gap-6 w-full cursor-pointer hover:bg-gray-50 text-xl font-semibold',
																	{
																		'text-primary': isActive,
																		'bg-gray-200': focus,
																	},
																)}>
																<div>
																	<Avatar
																		size={5}
																		imageAlt={account.name}
																		url={defaultImage}
																		defaultUrl={defaultImage}
																	/>
																</div>
																<div className='w-max'>
																	<div>{account.organization_name}</div>
																	<div>
																		{account.name +
																			' (' +
																			account.currency +
																			' ' +
																			formatMoney(account.account_balance || 0) +
																			')'}
																	</div>
																</div>
																<div>
																	<CheckCircleIcon
																		className={cn('w-10 text-primary', {
																			hidden: !isActive,
																		})}
																	/>
																</div>
															</div>
														)}
													</MenuItem>

													<hr className='w-4/5 mx-auto' />
												</Fragment>
											)
										})}
									</>
								)}

								{userNavigation.map(item => (
									<MenuItem key={item.name}>
										{({ focus }) => (
											<NavLink
												to={item.to ?? '#'}
												className={cn(
													focus ? 'bg-gray-100' : '',
													'px-[3rem] py-[1.5rem] text-[1.4rem] leading-[2.4rem] text-gray-600 flex items-center gap-3',
												)}
												onClick={item.onClick}>
												{<item.icon className='h-10 w-10 text-gray-400' />}
												{item.name}
											</NavLink>
										)}
									</MenuItem>
								))}
							</MenuItems>
						</Transition>
					</Menu>
				</div>
			</div>
		</div>
	)
}

export default TopHeader

const CurrentBalance = ({ credentials }: { credentials: Credentials }) => {
	const { t } = useTranslation(['header'])

	const [accountBalanceState, setAccountBalanceStatus] = useState<BalanceStatus>({ status: 'pending' })

	const { data: accountBalanceData, error: accountBalanceError } = useAccountBalance({
		accountId: credentials.accountId.value,
	})

	useEffect(() => {
		if (!accountBalanceData && !accountBalanceError) return setAccountBalanceStatus({ status: 'pending' })
		if (accountBalanceData) {
			return setAccountBalanceStatus({ status: 'resolved', data: accountBalanceData.data })
		}
		if (accountBalanceError) {
			// setErrorMessage(accountBalanceError.errorMessage)
			setAccountBalanceStatus({ status: 'rejected' })
		}
	}, [accountBalanceData, accountBalanceError, setAccountBalanceStatus])

	return (
		<div>
			{accountBalanceState.status === 'pending' && (
				<Loading className='AuxHeaderLinks--balance' width='10rem' height='4rem' />
			)}
			{accountBalanceState.status === 'rejected' && (
				<span
					className='AuxHeaderLinks--balance'
					data-testid='failed-to-load-balance'
					onClick={() => window.location.reload()}>
					{t('balanceState.rejected')}
				</span>
			)}
			{accountBalanceState.status === 'resolved' && (
				<span data-testid='account-balance' className='bg-[#BFFCDE] text-[#067F43] text-xl py-4 px-6 rounded-md'>
					{accountBalanceState.data.currency} {formatMoney(accountBalanceState.data.converted_balance)}
				</span>
			)}
		</div>
	)
}
