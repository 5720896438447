// @flow
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, IconAction } from 'ui-common'

import './index.sass'

type Props = {
	errors: Object,
	'data-testid': string,
	autoComplete?: string,
	label: string,
	name: string,
	placeholder?: string,
}
const PasswordComponent: React$AbstractComponent<Props, any> = React.forwardRef<Props, any>((props, ref) => {
	const [showPassword, setShowPassword] = useState(false)

	const handleToggleShowPassword = () => setShowPassword(s => !s)

	const { t } = useTranslation(['ui-common/password-component'])
	return (
		<div className='PasswordComponent__Container'>
			<Input {...props} type={showPassword ? 'text' : 'password'} fit ref={ref} />
			<div className={`PasswordContainer__inputContainer`}>
				<IconAction
					type='button'
					stroke='#84879A'
					aria-label={showPassword ? t('ariaLabel.hidePassword') : t('ariaLabel.showPassword')}
					onClick={handleToggleShowPassword}
					className={`PasswordContainer__input__icon`}
					name={showPassword ? 'eye' : 'eyeOff'}></IconAction>
			</div>
		</div>
	)
})

export default PasswordComponent
