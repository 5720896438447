// @flow
import React, { type Node } from 'react'

import { ReactComponent as Empty } from '../../img/AfricanEmptyState.svg'

import './styles.sass'

type Props = {
	children: Node,
}

export default function DefaultErrorComponent(props: Props): React$Element<'div'> {
	return (
		<div className='DefaultErrorComponent'>
			<Empty />
			<div className='DefaultErrorComponent__Children'>{props.children}</div>
		</div>
	)
}
