import React, { lazy, Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { Route, Switch, withRouter, useLocation, Redirect, useHistory } from 'react-router-dom'

import userAuthenticationState from 'logic/auth'
import { removeUniqueIdFromPath, trackPage } from 'utils/analytics'
import generatePageTitle from 'utils/generatePageTitle'

import { useSentryUser } from '../monitoring/sentry'

import Banner from './Banner'

//loading screens
import FullpagetableWithHeader from './CommonLoadingScreens/fullpagetablewithheader'
import FirststepSMSSendingLoadingScreen from './Sendsms/loading/firststep'
import LoadingSettings from './Settings/loading'
import LoadingMessageDetails from './MessageDetails/Loading'
import LoadingFiles from './Files/LoadingFiles'
import LoadingLoginScreen from './Login/loading'
import LoadingSignUpScreen from './Signup/loadingSignup'
import LoadingAnalyticsPage from './Analytics/loading'
import LoadingDetailScreen from './Surveys/loading/LoadingDetailScreen'
import LoadingSendScreen from './Surveys/loading/LoadingSendScreen'
import BulkMoneyFirstStepLoading from './BulkMoneySender/loading/BulkMoneyFirstStepLoading'
import { isProductionApp } from '../const'
import APIKeysLoading from './APIKeys/loading'
import SurveyRoutes from './routes/SurveyRoutes'
import WhatsappRoutes from './routes/WhatsappRoutes'

const Login = lazy(() => import(/* webpackChunkName: "login-page" */ './Login'))
const Signup = lazy(() => import(/* webpackChunkName: "signup-page" */ './Signup'))
const PasswordRecovery = lazy(() => import(/* webpackChunkName: "forgot-password-page" */ './ForgotPassword'))
const OtpVerification = lazy(() => import(/* webpackChunkName: "forgot-password-page" */ './OtpVerification'))
const PhoneVerifivation = lazy(() => import(/* webpackChunkName: "phone-verification-page" */ './Phoneverification/authWrapper'))
const EmailVerificationSent = lazy(
	() => import(/* webpackChunkName: "email-verification-page" */ './Emailverification/authWrapper'),
)
const EmailConfirmed = lazy(() => import(/* webpackChunkName: "email-confirmed-page" */ './EmailConfirmed/authWrapper'))
const ResetPassword = lazy(() => import(/* webpackChunkName: "reset-password-page" */ './ResetPassword'))
const Inbox = lazy(() => import(/* webpackChunkName: "inbox-page" */ './Inbox'))
const Settings = lazy(() => import(/* webpackChunkName: "settings-page" */ './Settings'))
const SendSMS = lazy(() => import(/* webpackChunkName: "sendsms-page" */ './Sendsms'))
const ApprovalDetails = lazy(() => import(/* webpackChunkName: "ApprovalDetails-page" */ './Sendsms/views/ApprovalDetails'))
const Billing = lazy(() => import(/* webpackChunkName: "billing-page" */ './Billing'))
const PaypalConfirmation = lazy(() => import(/* webpackChunkName: "paypal-confirmation-page" */ './Billing/paypalConfirmation'))
const Outbox = lazy(() => import(/* webpackChunkName: "outbox-page" */ './Outbox'))
const Contacts = lazy(() => import(/* webpackChunkName: "contacts-page" */ './Contacts'))
const ContactsDraft = lazy(() => import(/* webpackChunkName: "contacts-draft-page" */ './Contacts/views/contactDraft/index'))
const BusinessAccount = lazy(() => import(/* webpackChunkName: "business-account-page" */ './BusinessAccount'))
const MessageDetailsPage = lazy(() => import(/* webpackChunkName: "message-details-page" */ './MessageDetails'))
const FileAttachmentPage = lazy(() => import(/* webpackChunkName: "attachments-page" */ './Files'))
const PageNotFound = lazy(() => import(/* webpackChunkName: "not-found-page" */ './PageNotFound'))
const BlockContactInfoUpdate = lazy(() => import(/* webpackChunkName: "block-contact-info-page" */ './BlockContactInfoUpdate'))
const Analytics = lazy(() => import(/* webpackChunkName: "analytics-page" */ './Analytics'))
const APIKeys = lazy(() => import(/* webpackChunkName: "api-keys-page" */ './APIKeys'))
const USSD = lazy(() => import(/* webpackChunkName: "api-keys-page" */ './USSD'))

const Surveys = lazy(() => import(/* webpackChunkName: "surveys-page" */ './Surveys'))
const SurveyOutbox = lazy(() => import(/* webpackChunkName: "survey-responses-page" */ './Surveys/Outbox'))

const BulkData = lazy(() => import(/* webpackChunkName: "Bulk-data-page" */ './BulkData'))
const SendBulk = lazy(() => import(/* webpackChunkName: "Bulk-data-send" */ './BulkData/SendBulk'))
const TransactionDetails = lazy(() => import(/* webpackChunkName: "Bulk-details-page" */ './BulkData/TransactionDetails'))

const BulkMoneySender = lazy(() => import(/*webpackChunkName: "Bulk-money-sender-page" */ './BulkMoneySender/BulkMoneySender'))
const BulkMoneySenderOutbox = lazy(
	() => import(/*webpackChunkName: "Bulk-money-sender-page outbox" */ './BulkMoneySender/outbox'),
)
// const TransactionDetailsPage = lazy(() =>
// 	import(/*webpackChunkName: "Bulk-money-sender-page Transaction Details Page" */ './BulkMoneySender/TransactionDetails'),
// )
const Airtime = lazy(() => import(/* webpackChunkName: "Airtime" */ './Airtime'))
const SendAirtime = lazy(() => import(/* webpackChunkName: "SendAirtime" */ './Airtime/SendAirtime'))
const AirtimeTransactionDetails = lazy(
	() => import(/* webpackChunkName: "Airtime-details-page" */ './Airtime/TransactionDetails'),
)

export const PrivateRoute = ({ component: Component, ...rest }) => {
	let history = useHistory()
	useEffect(() => {
		if (history.location.pathname !== '/contacts') {
			localStorage.removeItem('isAddFromList')
			localStorage.removeItem('groupObject')
		}
	}, [history.location.pathname])
	const authState = userAuthenticationState()

	return (
		<Route
			{...rest}
			render={props => {
				return authState.state === 'authenticated' ? (
					<>
						<Banner />
						<Component {...props} {...rest} credentials={authState.credentials} />
					</>
				) : (
					<Redirect to={`/login?prev=${rest.location.pathname}`} />
				)
			}}
		/>
	)
}

const UnProtectedRoute = ({ component: Component, ...rest }) => {
	const authState = userAuthenticationState()

	return (
		<Route
			{...rest}
			render={props => {
				return authState.state === 'authenticated' ? <Redirect to='/sendsms' /> : <Component {...props} {...rest} />
			}}
		/>
	)
}

const UniversalRoute = ({ component: Component, ...rest }) => {
	const authState = userAuthenticationState()
	// @ts-ignore

	const credentials = (authState.state = 'authenticated' ? authState.credentials : null)
	return <Route {...rest} render={props => <Component {...props} {...rest} credentials={credentials} />} />
}

function ScrollToTop({ location, children }) {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location])
	return children
}

// @ts-ignore
const RouterScroll = withRouter(ScrollToTop)

const LoadingScreens = () => {
	let { pathname } = useLocation()
	const screens = {
		analytics: <LoadingAnalyticsPage />,
		'api-keys': <APIKeysLoading />,
		inbox: <FullpagetableWithHeader />,
		outbox: <FullpagetableWithHeader />,
		contacts: <FullpagetableWithHeader />,
		billing: <FullpagetableWithHeader />,
		'business-account': <FullpagetableWithHeader />,
		enterprise: <FullpagetableWithHeader />,
		sendsms: <FirststepSMSSendingLoadingScreen />,
		settings: <LoadingSettings />,
		messages: <LoadingMessageDetails />,
		scheduled: <LoadingMessageDetails />,
		files: <LoadingFiles />,
		login: <LoadingLoginScreen />,
		'forgot-password': <LoadingLoginScreen />,
		'reset-password': <LoadingLoginScreen />,
		'account-verification': <LoadingLoginScreen />,
		'email-verification-sent': <LoadingLoginScreen />,
		'accounts/register/confirm/': <LoadingLoginScreen />,
		'block-contact-update/': <LoadingLoginScreen />,
		'confirm-updated-email': <LoadingLoginScreen />,
		'survey-details': <LoadingDetailScreen />,
		register: <LoadingSignUpScreen />,
		questions: <LoadingDetailScreen />,
		responses: <LoadingDetailScreen />,
		'send-survey': <LoadingSendScreen />,
		surveys: <FullpagetableWithHeader />,
		'send-bulk-money/new': <BulkMoneyFirstStepLoading />,
		'send-bulk-money': <FullpagetableWithHeader />,
		'bulk-data': <FullpagetableWithHeader />,
		airtime: <FullpagetableWithHeader />,
		'': false,
	}
	let matchingPathName = ''
	for (const [key] of Object.entries(screens)) {
		if (pathname.includes(key)) {
			matchingPathName = key
			break
		}
	}
	return screens[matchingPathName] || <div>Loading...</div>
}

export default function Routes(props) {
	const location = useLocation()
	const page = removeUniqueIdFromPath(location)
	const pageTitle = generatePageTitle(page)
	trackPage({ page, path: location.pathname, title: pageTitle })
	const authState = userAuthenticationState()
	// @ts-ignore
	const credentials = authState.credentials || null
	useSentryUser({ credentials })

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				{isProductionApp && <script async src='https://www.googletagmanager.com/gtag/js?id=G-KZLZEV9EJ5'></script>}
			</Helmet>
			<RouterScroll>
				<Suspense fallback={<LoadingScreens />}>
					<>
						<Switch location={location} key={location.pathname}>
							<Route path='/' exact render={() => <Redirect to='/sendsms' />} />
							<UnProtectedRoute {...props} path='/accounts/login' exact component={Login} />
							<UnProtectedRoute {...props} path='/accounts/register' exact component={Signup} />
							<UnProtectedRoute {...props} path='/login' exact component={Login} />
							<UnProtectedRoute {...props} path='/register' exact component={Signup} />
							<UnProtectedRoute {...props} path='/forgot-password' exact component={PasswordRecovery} />
							<UniversalRoute {...props} path='/phone-verification' exact component={PhoneVerifivation} />
							<UniversalRoute {...props} path='/email-verification' exact component={EmailVerificationSent} />
							<UniversalRoute {...props} path='/otp-verification' exact component={OtpVerification} />
							<UnProtectedRoute
								{...props}
								path='/confirm-email/:userId/:activationKey'
								exact
								component={EmailConfirmed}
							/>
							<PrivateRoute
								{...props}
								path='/confirm-updated-email/:userId/:activationKey'
								exact
								component={EmailConfirmed}
							/>
							<Route
								{...props}
								path='/reset-password'
								exact
								render={routerprops => <ResetPassword {...routerprops} {...props} />}
							/>
							<UniversalRoute
								{...props}
								path='/block-contact-update/:userId/:uniqueKey'
								exact
								component={BlockContactInfoUpdate}
							/>
							<PrivateRoute {...props} path='/inbox' exact component={() => <Redirect to='/sendsms/inbox' />} />
							<Route path={['/settings', '/settings/:settingsView']}>
								<PrivateRoute {...props} path='/settings' exact component={Settings} />
								<PrivateRoute {...props} path='/settings/:settingsView' component={Settings} />
							</Route>
							<Route
								path={[
									'/sendsms',
									'/sendsms/:currentstep',
									'/sendsms/:draftId/review-message',
									'/sendsms/:currentstep/:variant',
								]}>
								<Switch location={location} key={location.pathname}>
									<PrivateRoute
										{...props}
										path='/sendsms'
										exact
										component={() => <Redirect to='/sendsms/compose' />}
									/>
									<PrivateRoute {...props} path='/sendsms/compose' exact component={SendSMS} />
									<PrivateRoute {...props} path='/sendsms/inbox' exact component={Inbox} />
									<PrivateRoute {...props} path='/sendsms/outbox' exact component={Outbox} />
									<PrivateRoute {...props} path='/sendsms/outbox/:view' component={Outbox} />
									<PrivateRoute
										{...props}
										path='/sendsms/:draftId/review-message'
										component={ApprovalDetails}
									/>
									<PrivateRoute {...props} path='/sendsms/:currentstep/:variant' component={SendSMS} />
									<PrivateRoute {...props} path='/sendsms/:currentstep' component={SendSMS} />
								</Switch>
							</Route>

							<Route path={['/whatsapp', '/whatsapp/*']} render={() => <WhatsappRoutes {...props} />} />

							<Route path={['/billing', '/billing/:currentView']}>
								<Switch>
									<PrivateRoute {...props} path='/billing' exact component={Billing} />
									<PrivateRoute {...props} path='/billing/paypal-confirm/:id' component={PaypalConfirmation} />
									<PrivateRoute {...props} path='/billing/:currentView/:currentSubView' component={Billing} />
									<PrivateRoute {...props} path='/billing/:currentView' component={Billing} />
								</Switch>
							</Route>
							<Route path={['/contacts', '/contacts/:contactsView', '/contacts/groups/:groupView']}>
								<Switch>
									<PrivateRoute {...props} path='/contacts' exact component={Contacts} />
									<PrivateRoute {...props} path='/contacts/groups/:groupView' component={Contacts} />
									<PrivateRoute {...props} path='/contacts/draft/:contactsDraftID' component={ContactsDraft} />
									<PrivateRoute {...props} path='/contacts/:contactsView/:blockOption' component={Contacts} />
									<PrivateRoute {...props} path='/contacts/:contactsView' component={Contacts} />
								</Switch>
							</Route>
							<PrivateRoute {...props} path='/outbox' exact component={() => <Redirect to='/sendsms/outbox' />} />

							<PrivateRoute {...props} path='/messages/:messageID' component={MessageDetailsPage} />
							<PrivateRoute {...props} path='/scheduled/:messageID' component={MessageDetailsPage} />

							<Route
								path={[
									'/business-account',
									'/business-account/:accountView',
									'/business-account/invite',
									'/business-account/invite/:accountSubView',
									'/business-account/accounts/add',
								]}>
								<Switch>
									<PrivateRoute {...props} path='/business-account' exact component={BusinessAccount} />

									<PrivateRoute
										{...props}
										path='/business-account/:accountView/:accountSubView/:accountDetailsRoute'
										component={BusinessAccount}
									/>
									<PrivateRoute
										{...props}
										path='/business-account/:accountView/:accountSubView'
										component={BusinessAccount}
									/>
									<PrivateRoute {...props} path='/business-account/:accountView' component={BusinessAccount} />
								</Switch>
							</Route>
							<Route path={['/bulk-data']}>
								<Switch>
									<PrivateRoute {...props} path='/bulk-data' exact component={SendBulk} />
									<PrivateRoute {...props} path='/bulk-data/:section' exact component={BulkData} />
									<PrivateRoute {...props} path='/bulk-data/bulk-details/:id' component={TransactionDetails} />
									<PrivateRoute {...props} path='/bulk-data/scheduled/:id' component={TransactionDetails} />
								</Switch>
							</Route>
							<Route path='/files/:uuid' exact render={rest => <FileAttachmentPage {...props} {...rest} />} />
							<PrivateRoute {...props} path='/analytics' exact component={Analytics} />
							<PrivateRoute {...props} path='/api-keys' exact component={APIKeys} />
							<PrivateRoute {...props} path='/ussd' exact component={USSD} />

							<PrivateRoute {...props} path='/surveys/outbox' component={SurveyOutbox}></PrivateRoute>
							<PrivateRoute {...props} path='/surveys/messages/:messageID' component={MessageDetailsPage} />
							<Route
								path={['/surveys/:id/*', '/surveys/new', '/surveys/outbox', '/surveys/:id']}
								render={() => <SurveyRoutes {...props} />}
							/>
							<PrivateRoute {...props} path='/surveys' component={Surveys} />

							<Route path={['/airtime']}>
								<Switch>
									<PrivateRoute
										{...props}
										path='/airtime/airtime-details/:id'
										component={AirtimeTransactionDetails}
									/>
									<PrivateRoute
										{...props}
										path='/airtime/scheduled/:id'
										component={AirtimeTransactionDetails}
									/>
									<PrivateRoute {...props} path='/airtime' exact component={SendAirtime} />
									<PrivateRoute {...props} path='/airtime/:section' component={Airtime} />
								</Switch>
							</Route>
							{process.env.NODE_ENV === 'development' && (
								<Route path={['/send-money', '/transaction-details']}>
									<Switch>
										<PrivateRoute
											{...props}
											path='/send-money/pending-drafts/:draftId'
											component={BulkMoneySender}></PrivateRoute>
										<PrivateRoute
											{...props}
											exact
											path='/send-money'
											component={BulkMoneySender}></PrivateRoute>
										{/* <PrivateRoute
										{...props}
										path='/transaction-details'
										component={TransactionDetailsPage}></PrivateRoute> */}
										<PrivateRoute
											{...props}
											path='/send-money/:section'
											component={BulkMoneySenderOutbox}></PrivateRoute>
									</Switch>
								</Route>
							)}
							<Route component={PageNotFound} />
						</Switch>
					</>
				</Suspense>
			</RouterScroll>
		</>
	)
}
