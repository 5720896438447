// @flow

import React, { useMemo, useState, useCallback } from 'react'

import './style.sass'
type Props = {
	url: string,
	size: number,
	units?: string,
	imageAlt: string,
	defaultUrl: string,
}

type ImageProps = {
	src: string,
	loadError: boolean,
}

export default function Avatar({ url, size, units = 'rem', imageAlt, defaultUrl }: Props): React$Element<'div'> {
	const [imageProps, setImageProps] = useState<ImageProps>(() => ({ src: url, loadError: false }))
	const containerStyle = useMemo(
		() => ({
			width: `${size}${units}`,
			height: `${size}${units}`,
		}),
		[size, units],
	)

	const handleLoadError = useCallback(() => {
		if (!imageProps.loadError) {
			setImageProps({ src: defaultUrl, loadError: true })
		}
	}, [defaultUrl, imageProps.loadError])
	return (
		<div className='AvatarContainer' style={containerStyle}>
			<img onError={handleLoadError} src={imageProps.src} alt={imageAlt} />
		</div>
	)
}
