import React, { lazy } from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from 'website'
import SurveyProvider from 'website/Surveys/views/SurveyQuestions/SurveyProvider'

const SurveyDetails = lazy(() => import(/* webpackChunkName: "survey-details-page" */ '../Surveys/views/SurveyDetails'))

const SurveyQuestions = lazy(() => import(/* webpackChunkName: "survey-questions-page" */ '../Surveys/views/SurveyQuestions'))
const SurveyOutro = lazy(() => import(/* webpackChunkName: "survey-questions-page" */ '../Surveys/views/SurveyOutro'))

const SendSurvey = lazy(() => import(/* webpackChunkName: "send-survey-page" */ '../Surveys/views/SendSurvey'))

const SurveyRespondents = lazy(
	() => import(/* webpackChunkName: "survey-responses-page" */ '../Surveys/views/SurveyResponses/SurveyRespondents'),
)

const SurveyRoutes = (props: React.JSX.IntrinsicAttributes & { [x: string]: any; component: any }) => {
	return (
		<SurveyProvider setSuccessMessage={props.setSuccessMessage} setErrorMessage={props.setErrorMessage}>
			<Switch>
				<PrivateRoute {...props} path='/surveys/:id/send' component={SendSurvey}></PrivateRoute>
				<PrivateRoute {...props} path='/surveys/:id/responses' component={SurveyRespondents}></PrivateRoute>
				<PrivateRoute {...props} path='/surveys/:id/questions' component={SurveyQuestions}></PrivateRoute>
				<PrivateRoute {...props} path='/surveys/:id/outro' component={SurveyOutro}></PrivateRoute>
				<PrivateRoute {...props} path='/surveys/new' component={SurveyDetails} />
				<PrivateRoute {...props} path='/surveys/:id' component={SurveyDetails}></PrivateRoute>
				<PrivateRoute {...props} path='/surveys/:id/send' exact component={SendSurvey} />
				<PrivateRoute {...props} path='/surveys/:id/send/:currentstep/:variant' component={SendSurvey} />
				<PrivateRoute {...props} path='/surveys/:id/send/:currentstep' component={SendSurvey} />
			</Switch>
		</SurveyProvider>
	)
}

export default SurveyRoutes
