import React, { useState, createContext, useContext } from 'react'
import cx from 'classnames'
import './style.sass'
import { Icon } from '../Icon'

const CollapsibleContext = createContext()

export const Collapsible = ({ children, className, initialVisibleItem }) => {
	const [visible, setVisible] = useState(initialVisibleItem)

	const cs = cx('Collapsible', className)

	const toggleCollapsible = id => {
		if (visible === id) {
			setVisible()
		} else {
			setVisible(id)
		}
	}

	return (
		<CollapsibleContext.Provider value={{ visible, toggleCollapsible }}>
			<div className={cs}>{children}</div>
		</CollapsibleContext.Provider>
	)
}

export const CollapsibleItem = ({ id, title, className, children }) => {
	const collapsibleContext = useContext(CollapsibleContext)

	const cs = cx('Collapsible__item', className, { 'Collapsible__item--open': collapsibleContext.visible === id })

	const onClick = () => collapsibleContext.toggleCollapsible(id)

	return (
		<div className={cs}>
			<button className='Collapsible__title' onClick={onClick}>
				{title}
				<Icon name='chevron-down' width={18} height={18}></Icon>
			</button>
			<div className='Collapsible__content'>
				<div className='Collapsible__text'>{children}</div>
			</div>
		</div>
	)
}
