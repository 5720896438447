// @flow
import React, { type Node } from 'react'
import cs from 'classnames'

import './style.sass'

export type BoldTextProps = {
	children: Node,
	className?: string,
}
export default function BoldText({ children, className = '' }: BoldTextProps): React$Element<'b'> {
	const cx: string = cs('BoldText', className)
	return <b className={cx}>{children}</b>
}
