// @flow
import React, { useState, useEffect } from 'react'
import { Icon } from '../Icon'
import { Checkbox } from '../FormElements'
import './style.sass'

type ItemToBeDisplayed = Object

type MultiSelectProps = {
	label: string,
	toggleItem: (ItemToBeDisplayed, idx: number) => void,
	selectedItems: ItemToBeDisplayed[],
	allItems: ItemToBeDisplayed[],
	showListOnTop?: boolean,
}

export const MultiSelect = ({
	selectedItems,
	toggleItem,
	allItems,
	label,
	showListOnTop,
}: MultiSelectProps): React$Element<'div'> => {
	const [listVisible, setListVisible] = useState(false)

	useEffect(() => {
		const makeListInvisible = () => setListVisible(false)
		window.addEventListener('click', makeListInvisible)
		return () => {
			window.removeEventListener('click', makeListInvisible)
		}
	}, [])

	const toggleDropdown = (e: SyntheticEvent<HTMLElement>) => {
		e.stopPropagation()
		setListVisible(!listVisible)
	}

	const internalToggleItemFunction = (item: ItemToBeDisplayed, idx: number) => (e: SyntheticEvent<HTMLElement>) => {
		e.stopPropagation()
		toggleItem(item, idx)
	}

	return (
		<div className='MultiSelect'>
			{label && <label className='MultiSelect__label'>{label}</label>}
			<span className='MultiSelect__select' onClick={toggleDropdown}>
				<div className='MultiSelect__select-content'>
					{selectedItems.length === 0 && <span className='MultiSelect__placeholder'>{label}</span>}
					{selectedItems.length > 0 &&
						selectedItems.map((item, idx) => {
							return (
								<div key={item.id} onClick={e => e.stopPropagation()} className='MultiSelect__select-item'>
									{item.name}
									<Icon
										name='close'
										stroke='#84879A'
										width={14}
										height={14}
										onClick={internalToggleItemFunction(item, idx)}
									/>
								</div>
							)
						})}
				</div>
			</span>
			{listVisible && (
				<ul className='MultiSelect__list'>
					{allItems.map((item, idx) => {
						return (
							<li className='MultiSelect__list-item' key={item.id} onClick={internalToggleItemFunction(item, idx)}>
								<Checkbox checked={Boolean(item.checked)} label={item.name} readOnly />
							</li>
						)
					})}
				</ul>
			)}
		</div>
	)
}
