// @flow

export default function shortenLargeNumber(num: number): number | string {
	var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
		decimal

	for (var i = units.length - 1; i >= 0; i--) {
		decimal = Math.pow(1000, i + 1)

		if (num <= -decimal || num >= decimal) {
			return '~' + (num / decimal).toFixed(0) + units[i]
		}
	}
	return num
}
