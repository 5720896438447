// @flow
import React from 'react'
import { Loading } from 'ui-common'

import './loading.sass'

export default function LoadingDetailScreen(): React$Element<'div'> {
	return (
		<div className='SurveyPageLoading'>
			<Loading variant='rect' width='20%' height='5%' className='' />
			<div className='SurveyNavLoading'>
				<Loading variant='rect' width='20%' height='100%' className='SurveyNavLoadingTab' />
				<Loading variant='rect' width='20%' height='100%' className='SurveyNavLoadingTab' />
				<Loading variant='rect' width='20%' height='100%' className='SurveyNavLoadingTab' />
			</div>
			<Loading variant='rect' width='100%' height='80%' className='' />
		</div>
	)
}
