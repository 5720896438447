import React, { Fragment, useState } from 'react'
import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { Cog6ToothIcon, XMarkIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import type { Navigation } from './DashboardLayout'
import cn from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'

const MobileNavigation = ({
	navigation,
	sidebarOpen,
	setSidebarOpen,
}: {
	navigation: Navigation[]
	sidebarOpen: boolean
	setSidebarOpen: any
}): any => {
	return (
		<Transition show={sidebarOpen} as={Fragment}>
			<Dialog as='div' className='relative z-50 lg:hidden' onClose={setSidebarOpen}>
				<TransitionChild
					as={Fragment}
					enter='transition-opacity ease-linear duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='transition-opacity ease-linear duration-300'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-gray-900/80' />
				</TransitionChild>

				<div className='fixed inset-0 flex'>
					<TransitionChild
						as={Fragment}
						enter='transition ease-in-out duration-300 transform'
						enterFrom='-translate-x-full'
						enterTo='translate-x-0'
						leave='transition ease-in-out duration-300 transform'
						leaveFrom='translate-x-0'
						leaveTo='-translate-x-full'>
						<DialogPanel className='relative mr-[6.4rem] flex w-full max-w-[32rem] flex-1'>
							<TransitionChild
								as={Fragment}
								enter='ease-in-out duration-300'
								enterFrom='opacity-0'
								enterTo='opacity-100'
								leave='ease-in-out duration-300'
								leaveFrom='opacity-100'
								leaveTo='opacity-0'>
								<div className='absolute left-full top-0 flex w-[6.4rem] justify-center pt-[2rem]'>
									<button type='button' className='-m-[1rem] p-[1rem]' onClick={() => setSidebarOpen(false)}>
										<span className='sr-only'>Close sidebar</span>
										<XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
									</button>
								</div>
							</TransitionChild>
							{/* Sidebar component, swap this element with another sidebar if you like */}
							<div className='flex grow flex-col gap-y-[1.25rem] overflow-y-auto bg-white px-[2.4rem] pb-[1.6rem]'>
								<div className='flex h-[6.4rem] shrink-0 items-center'>
									<NavLink to='/'>
										<img className='h-[3.2rem] w-auto' src='../../img/blue-logo.svg' alt='SMSLeopard logo' />
									</NavLink>
								</div>
								<nav className='flex flex-1 flex-col'>
									<ul className='flex flex-1 flex-col gap-y-7'>
										<li>
											<ul className='-mx-[0.8rem] space-y-[0.4rem]'>
												{navigation.map((item: Navigation) => {
													if (item.isHidden) return null
													return (
														<NavItem key={item.to} navItem={item} setSidebarOpen={setSidebarOpen} />
													)
												})}
											</ul>
										</li>

										<li className='mt-auto'>
											<NavLink
												to='/settings'
												className='group -mx-[0.8rem] flex gap-x-[1.2rem] rounded-[0.6rem] p-[0.8rem] text-[1.4rem] font-semibold leading-[2.4rem] text-gray-700 hover:bg-gray-50 hover:text-primary'>
												<Cog6ToothIcon
													className='h-[2.4rem] w-[2.4rem] shrink-0 text-gray-400 group-hover:text-primary'
													aria-hidden='true'
												/>
												Settings
											</NavLink>
										</li>
									</ul>
								</nav>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	)
}

export default MobileNavigation

const NavItem = ({ navItem, setSidebarOpen }: { navItem: Navigation; setSidebarOpen: any }) => {
	const location = useLocation()
	const [expanded, setExpanded] = useState(location.pathname.includes(navItem.to))

	return (
		<li key={navItem.to}>
			<div
				className={cn(
					'text-gray-700 hover:text-primary hover:bg-gray-50 cursor-pointer',
					'group flex gap-x-[1.2rem] rounded-md p-[0.8rem] text-[1.4rem] leading-[2.4rem] font-semibold items-center',
				)}
				onClick={() => setExpanded(currentState => !currentState)}>
				<navItem.icon className={cn('group-hover:text-primary', 'h-[2.4rem] w-[2.4rem] shrink-0')} aria-hidden='true' />
				{navItem.name}
				{expanded ? (
					<ChevronUpIcon className={cn('group-hover:text-primary ml-auto', 'h-[2rem] w-[2rem] shrink-0')} />
				) : (
					<ChevronDownIcon className={cn('group-hover:text-primary ml-auto', 'h-[2rem] w-[2rem] shrink-0')} />
				)}
			</div>
			{expanded && (
				<ul className='ml-8'>
					{navItem.headerLinks.map(headerLink => (
						<li key={headerLink.to} onClick={() => setSidebarOpen(false)}>
							<NavLink
								to={headerLink.isExternal ? { pathname: headerLink.to } : headerLink.to}
								target={headerLink.isExternal ? '_blank' : undefined}
								activeClassName='bg-gray-50 text-primary'
								className={cn(
									'text-gray-600 hover:text-primary hover:bg-gray-50',
									'group flex gap-x-[1.2rem] rounded-md p-[0.8rem] text-[1.25rem] leading-[2.4rem] font-semibold items-center',
								)}
								exact>
								<headerLink.icon
									className={cn('group-hover:text-primary', 'h-[2rem] w-[2rem] shrink-0')}
									aria-hidden='true'
								/>
								{headerLink.name}
							</NavLink>
							<ul className='ml-8'>
								{headerLink.menuLinks?.map(menuLink => (
									<li key={menuLink.to}>
										<NavLink
											to={menuLink.to}
											activeClassName='bg-gray-50 text-primary'
											className={cn(
												'text-gray-500 hover:text-primary hover:bg-gray-50',
												'group flex gap-x-[1.2rem] rounded-md p-[0.8rem] text-[1.15rem] leading-[2.4rem] font-semibold',
											)}>
											{menuLink.name}
										</NavLink>
									</li>
								))}
							</ul>
						</li>
					))}
				</ul>
			)}
		</li>
	)
}
